import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { Container, Button } from "react-bootstrap";
import shield from 'assets/images/swoop-secure-shield-20px.svg';

const AuthLayout = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search)

  const [status, setStatus] = useState('Welcome, please sign in to continue.');  
  const code = queryParams.get('code');

  useEffect(() => {          
    window.swoop.configure({
      onSuccess: (user) => {        
        dispatch({
          type: "VERIFY_ADMIN",
          history,
          token: user.id_token
        });
      },
      onShow(w) {
        setStatus('Logging in...');
      },
      onClose(w) {
        setStatus('Welcome, please sign in to continue.');
      },
      onLoading(w) {        
        setStatus('Success! Signing you in...');
      }      
    })

    if(!code) {
      window.swoop.in();
    }

  }, []);

  return (
    <Container fluid>
      <section className="vh-100" >
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
              <div className="card shadow-2-strong">
                <div className="card-body p-5 text-center">

                  {/* <h3 className="mb-5">Sign in</h3> */}
                  {/* <img src={shield} style={{ width: 100, height: 100 }} /> */}
                  <br />
                  <br />
                  <div className="form-outline mb-4">
                    {status}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </Container>
  );
}

export default AuthLayout;
