import React, { useState, useEffect } from "react";
import { Form, Button, Modal, InputGroup } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import SwoopToast from 'views/components/SwoopToast';
import OrganizationTransferModal from "views/components/modals/OrganizationTransferModal";
import skull from 'assets/images/skull-icon.svg';
import { handleLoadToasts } from "utils/eventHandling";
import MixpanelClicks from 'mixpanel/MixpanelClicks';

const Settings = props => {
  const property = useSelector(state => state.dashboard.selectedProperty);
  const loadStatus = useSelector(state => state.dashboard.loadStatuses && state.dashboard.loadStatuses.propertyLoadStatus);
  let admin = property.role === 0 ? true : false;
  const dispatch = useDispatch();
  const history = useHistory();

  const [name, setName] = useState(property.name);
  const [url, setUrl] = useState(property.url);
  const [redirect_uris, setRedirectUris] = useState(property.redirect_uris || []);
  const [newRedirectUri, setNewRedirectUri] = useState("");
  const [login_url, setLoginUrl] = useState(property.login_url);
  const [updateValidated, setUpdateValidated] = useState(false);
  const [redirectUriValidated, setRedirectUriValidated] = useState(null);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showFailureToast, setShowFailureToast] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [delete_name, setDeleteName] = useState(null);  

  useEffect(() => {
    setName(property.name);
    setUrl(property.url);
    setRedirectUris(property.redirect_uris);
    setLoginUrl(property.login_url);
  }, [property])

  useEffect(() => {
    handleLoadToasts(loadStatus, setShowSuccessToast, setShowFailureToast);
  }, [loadStatus])

  const addRedirectUri = () => {
    setUpdateValidated(false); // slight hack so that addUrl input field is only validated here and not upon form submission
    if (newRedirectUri.length > 0) {
      redirect_uris.push(newRedirectUri);
      setNewRedirectUri("");
      setRedirectUriValidated(null)
    } else {
      setRedirectUriValidated("was-validated")
    }
  }

  const handleRedirectURIChange = (e, i) => {
    const newRedirectUris = [...redirect_uris];
    newRedirectUris[i] = e.target.value;
    setRedirectUris(newRedirectUris);
  }

  const removeRedirectURI = i => {
    setRedirectUris(redirect_uris.slice(0, i).concat(redirect_uris.slice(i + 1)));
  }

  const handleFormSubmit = e => {
    e.preventDefault();
    e.stopPropagation();

    if (name.length > 0 & url.length > 0) {
      dispatch({
        type: "UPDATE_PROPERTY",
        organizationId: property.organization,
        propertyId: property._id,
        params: { name, url, redirect_uris, login_url }
      });
    } else {
      setUpdateValidated(true);
    }
  }

  const handleDeleteSubmit = e => {
    e.preventDefault();

    if (delete_name === name) {
      dispatch({
        type: "DELETE_PROPERTY",
        organizationId: property.organization,
        propertyId: property._id,
        history
      })
    }
  }

  return (
    <>
      {!props.testing && <MixpanelClicks elements={[]} view="Settings" />}
      <SwoopToast id="settings-success-toast" success={true} setShow={setShowSuccessToast} show={showSuccessToast} message="Settings Updated" />
      <SwoopToast success={false} setShow={setShowFailureToast} show={showFailureToast} message={"Failed to Update Settings"} />
      <h2>Settings</h2>
      <div className="heading-underline"></div>
      <Form noValidate validated={updateValidated} className="w-lg-60" onSubmit={handleFormSubmit}>
        <Form.Group>
          <Form.Label>App Name *</Form.Label>
          <Form.Text className="subtext">
            The name displayed to your users during authorization.
          </Form.Text>
          <Form.Control
            disabled={!admin}
            data-testid="property-name"
            placeholder="Enter your app/website name"
            type="text"
            name="name"
            value={name}
            onChange={e => setName(e.target.value)}
            required
          />
          <Form.Control.Feedback id="invalid-app-name" type="invalid">
            Please give your app a name
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>App URL *</Form.Label>
          <Form.Text className="subtext">
            Home page of your app.
          </Form.Text>
          <Form.Control
            disabled={!admin}
            data-testid="property-url"
            placeholder="https://www.example.com"
            type="text"
            name="url"
            value={url}
            onChange={e => setUrl(e.target.value)}
            required
          />
          <Form.Control.Feedback type="invalid">
            Please fill in your app's url
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Login URL</Form.Label>
          <Form.Text className="subtext">
            The url a user will be directed to if they click on a stale authentication link.
          </Form.Text>
          <Form.Control
            disabled={!admin}
            data-testid="property-login-url"
            placeholder="https://www.example.com/login"
            type="text"
            name="login_url"
            value={login_url}
            onChange={e => setLoginUrl(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Redirect URLs</Form.Label>
          <Form.Text className="subtext">
            The url that the 1-Click Login API will redirect to once it has granted an authorization code. The value you pass can be customized depending on how you handle requests from the 1-Click Login API.
          </Form.Text>
          {redirect_uris.map((redirect_uri, index) => (
            <InputGroup key={index} className="mb-3 separated">
              <Form.Control
                disabled={!admin}
                data-testid={`property-redirect-uri-${index}`}
                placeholder="https://www.example.com/auth/swoop/callback"
                type="text"
                name="redirect_uris[]"
                value={redirect_uri}
                onChange={event => handleRedirectURIChange(event, index)}
              />
              <InputGroup.Append>
                <Button className="remove-btn attached-item" data-testid={`delete-redirect-uri-${index}`} onClick={() => removeRedirectURI(index)} disabled={!admin}>
                  Remove
                </Button>
              </InputGroup.Append>
            </InputGroup>
          )
          )}
          <InputGroup id="add-redirect-uri" className={`separated ${redirectUriValidated}`}>
            <Form.Control
              disabled={!admin}
              data-testid='new-redirect-uri'
              placeholder="https://www.example.com/auth/swoop/callback"
              type="text"
              name="redirect_uris[]"
              value={newRedirectUri}
              onChange={e => setNewRedirectUri(e.target.value)}
              required={!updateValidated}
            />
            <InputGroup.Append>
              <Button data-testid='add-redirect-uri-button' className="secondary-btn attached-item" onClick={addRedirectUri} disabled={!admin}>
                Add URL
              </Button>
            </InputGroup.Append>
            <Form.Control.Feedback type="invalid" className="bg-primary mt-0 pt-1">
              Redirect URL cannot be blank
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Button data-testid="save-settings" type='submit' disabled={!admin} onClick={handleFormSubmit}>
          Update Settings
        </Button>
      </Form>
      <br></br>
      <div className="heading-underline"></div>

      <div className="my-5">
        <p className="secondary-text"><strong>TRANSFER TREBUCHET</strong></p>
        <p className="subtext my-2">
          Permanently transfer your app to another team. Transferring your app is irreversible.
        </p>
        <Button
          className="secondary-btn"
          onClick={() => setShowTransferModal(true)}
          disabled={!admin}
          data-testid="transfer-app-modal-button"
        >
          Transfer App
        </Button>
      </div>

      <div className="my-5">
        <p className="warning-text">DELETION DUNGEON</p>
        <p className="subtext my-2">
          Permanently delete your app? Deleting your app is irreversible.
        </p>
        <Button
          className="warning-button"
          onClick={() => setShowDeleteModal(true)}
          disabled={!admin}
          data-testid="delete-app-modal-button"
        >
          Delete App
        </Button>
      </div>

      <Modal show={showDeleteModal} centered onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton className="mt-2 justify-content-center">
          <img src={skull} alt="skull-icon" className="w-10 t-n110" />
        </Modal.Header>
        <Modal.Body className="pt-0">
          <Form noValidate onSubmit={handleDeleteSubmit}>
            <Form.Group>
              <h2>Delete App</h2>
              <Form.Text className="subtext">
                Deleting your app is irreversible. Enter the name of the app to confirm you want to permanently delete this app:
              </Form.Text>
              <Form.Control
                data-testid="delete-name"
                placeholder={`${property.name}`}
                name="delete_name"
                type="text"
                onChange={e => setDeleteName(e.target.value)}
                isInvalid={delete_name !== name}
              />
              <Form.Control.Feedback type="invalid">
                The name entered must match your app's name
              </Form.Control.Feedback>
            </Form.Group>
            <Button data-testid="delete-property" onClick={handleDeleteSubmit} disabled={!admin || delete_name !== name}>
              Delete
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <OrganizationTransferModal
        id="transfer-organization-modal"
        show={showTransferModal}
        onHide={() => setShowTransferModal(false)}      
        property={property}        
      />
    </>
  )
}

export default Settings;
