import { call, put, takeLatest } from "redux-saga/effects";
import axios from 'axios';
import { errorHandler } from 'utils/sagas';

const DASHBOARD_API_BASE_URL = process.env.REACT_APP_DASHBOARD_API_ENDPOINT;
const DASHBOARD_API_ENDPOINT = `${DASHBOARD_API_BASE_URL}/api`;

export function* fetchPropertyUsers(action){
  let url = `${DASHBOARD_API_ENDPOINT}/organizations/${action.organizationId}/properties/${action.propertyId}/users`;
  try {
    const { data } = yield call(axios.get, url);
    yield put({ type: "FULFILL_PROPERTY_USERS", payload: data })
  } catch (error) {
    console.log(error);
  }
}

export function* resetUserMfa(action){
  let body = {
    email: action.email
  }
  let url = `${DASHBOARD_API_ENDPOINT}/organizations/${action.organizationId}/properties/${action.propertyId}/users/mfa_reset`;
  const result = yield call(axios.post, url, body);
  // console.log(`sucess ${JSON.stringify(result.data)}`)
  if(!result) {
    throw new Error('User not found');
  }
}

export function* userSaga() {
  yield takeLatest("FETCH_PROPERTY_USERS", fetchPropertyUsers);
  yield takeLatest("RESET_USER_MFA", errorHandler(resetUserMfa, "UPDATE_LOAD_STATUS", "usersLoadStatus"));
}
