import React, { useState } from 'react';
import { Toast } from "react-bootstrap";
import checkmark from 'assets/images/checkmark.svg';

const SwoopToast = props => {
  const leftDivColor = props.success ? "bg-quarternary" : "warning-bg";

  return (
    <Toast id={props.id} onClose={() => props.setShow(false)} show={props.show} delay={3000} autohide>
        <div className={`${leftDivColor} br-left align-items-middle`}>
          {props.success ? 
            <img src={checkmark} alt="check mark" />
            :
            <span>!</span>
          }
        </div>
        <div className="align-items-middle text-wrapper br-right">
          {props.message}
          <button type="button" className="close ml-3" data-dismiss="toast" onClick={() => props.setShow(false)}> 
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </div>
    </Toast>
  )
}

export default SwoopToast