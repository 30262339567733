import React from "react";
import { Container } from "react-bootstrap";
import shield from 'assets/images/swoop-secure-shield-20px.svg';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);
        console.log(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            // return <h1>Something went wrong.</h1>;
            // return this.FallbackComponent;
            return (
                <Container fluid>
                    <section className="vh-100" >
                        <div className="container py-5 h-100">
                            <div className="row d-flex justify-content-center align-items-center h-100">
                                <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                                    <div className="card shadow-2-strong">
                                        <div className="card-body p-5 text-center">

                                            {/* <h3 className="mb-5">Sign in</h3> */}
                                            <img src={shield} style={{ width: 50, height: 50 }} />
                                            
                                            <div className="form-outline mb-4">
                                                <h3>An error occurred.</h3>
                                                <p>If problem persists, please email <a href="mailto:info@swoopnow.com">Swoop Support</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </Container>
            )
        }

        return this.props.children;
    }
}

export default ErrorBoundary;