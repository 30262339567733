import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import { authSaga } from "./sagas/authSagas";
import { propertySaga } from "./sagas/propertySagas";
import { propertyAdminSaga } from "./sagas/propertyAdminSagas";
import { mfaSaga } from "./sagas/mfaSagas";
import { userSaga } from "./sagas/userSagas";
import { organizationSaga } from "./sagas/organizationSagas";
import { organizationAdminSaga } from "./sagas/organizationAdminSagas";
import { billingSaga } from "./sagas/billingSagas";
import { analyticsSaga } from "./sagas/analyticsSagas";
import authReducer from './reducers/authReducer';
import dashboardReducer from "./reducers/dashboardReducer";
import organizationsReducer from "./reducers/organizationsReducer";
import billingReducer from "./reducers/billingReducer";
import analyticsReducer from "./reducers/analyticsReducer";

export const rootReducer = combineReducers( {
  auth: authReducer,
  dashboard: dashboardReducer,
  organizations: organizationsReducer,
  billing: billingReducer,
  analytics: analyticsReducer
} );

export function* rootSaga() {
  yield all( [ authSaga(),
    propertySaga(),
    propertyAdminSaga(),
    mfaSaga(),
    userSaga(),
    organizationSaga(),
    organizationAdminSaga(),
    billingSaga(),
    analyticsSaga(),
  ] );
}
