import { call, put, takeLatest } from "redux-saga/effects";
import axios from 'axios';
import { errorHandler } from 'utils/sagas';

const DASHBOARD_API_BASE_URL = process.env.REACT_APP_DASHBOARD_API_ENDPOINT;
const DASHBOARD_API_ENDPOINT = `${DASHBOARD_API_BASE_URL}/api`;
export const SWOOP_SERVICE_BASE_URL = process.env.REACT_APP_SWOOP_SERVICE_ENDPOINT;

export function* fetchMfaPlugins(action){
  try {
    let url = `${DASHBOARD_API_ENDPOINT}/organizations/${action.organizationId}/properties/${action.property._id}/mfa/all`;
    const { data: plugins } = yield call(axios.get, url );
    yield put({ type: "FULFILL_MFA_PLUGINS_LOCALLY", payload: plugins });
    const propertyPlugin = plugins.find(plugin => plugin._id === action.property.mfa.mfa_plugin)
    yield put({ type: "FULFILL_UPDATED_MFA", payload: { pluginDetails: propertyPlugin } })
  } catch (error) {
    console.log(error)
  }
}

export function* fetchPluginConfig(action){
  let url = `${SWOOP_SERVICE_BASE_URL}/mfa/${action.slug}/config.json`;
  try {
    const { data: admin } = yield call(axios.get, url);    
    yield put({ type: "FULFILL_UPDATED_MFA", payload: { config: admin.admin } })
  } catch (error) {
    console.log(error)
  }
}

export function* updatePropertyMfa(action){
  let url = `${DASHBOARD_API_ENDPOINT}/organizations/${action.organizationId}/properties/${action.propertyId}/mfa`;
  const { data } = yield call(axios.put, url, action.params);
  if(data.enabled){
    yield put({ type: "FULFILL_UPDATED_MFA", payload: {
      enabled: true,
      mfa_plugin: data.mfa_plugin._id,
      pluginDetails: data.mfa_plugin
    }});
    yield put({ type: "FETCH_PLUGIN_CONFIG", slug: data.mfa_plugin.slug });
  } else {
    yield put({ type: "FULFILL_DISABLE_MFA" })
  }
  yield put({ type: "FULFILL_UPDATED_MFA", payload: { settings: data.settings } })
  yield put({ type: "PROPERTIES_FETCH_ALL" });  
}


export function* mfaSaga() {
  yield takeLatest("FETCH_MFA_PLUGINS", fetchMfaPlugins);
  yield takeLatest("FETCH_PLUGIN_CONFIG", fetchPluginConfig);
  yield takeLatest("UPDATE_PROPERTY_MFA", errorHandler(updatePropertyMfa, "UPDATE_LOAD_STATUS", 'mfaLoadStatus'));
}
