import mixpanel from 'mixpanel-browser';
import { useCreateIdentity, useUpdateUser, useInitializePropertyInfo } from 'mixpanel/identityHooks';
import { useTrackViews } from 'mixpanel/eventHooks';

const Mixpanel = props => {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN)

  useCreateIdentity();
  useUpdateUser();
  useInitializePropertyInfo();
  useTrackViews(props.history);

  return null
}

export default Mixpanel