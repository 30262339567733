import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import CheckoutForm from 'views/components/forms/CheckoutForm';
import ConfirmModal from 'views/components/modals/ConfirmModal';
import RechargeModal from 'views/components/modals/RechargeModal';
import Table from 'react-bootstrap/Table'
import SwoopToast from 'views/components/SwoopToast';
import { handleLoadToasts } from "utils/eventHandling";

const Billing = () => {

  const dispatch = useDispatch();
  const organization = useSelector(state => state.organizations.selected);
  const billing = useSelector(state => state.billing || {});
  const costPerCredit = .008;
  const [showModal, setShowModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showRechargeModal, setShowRechargeModal] = useState(false);
  const [canRecharge, setCanRecharge] = useState(false);

  // TOASTS
  const loadStatus = useSelector(state => state.dashboard.loadStatuses && state.dashboard.loadStatuses.billingUpdateStatus);
  const [showSuccessToast, setShowSuccessToast] = useState(false);  
  const [showFailureToast, setShowFailureToast] = useState(false);
  const [failureToastMessage, setFailureToastMessage] = useState("");
  useEffect(() => {
    handleLoadToasts(loadStatus, setShowSuccessToast, setShowFailureToast);
  }, [loadStatus]);

  useEffect(() => {
    setCanRecharge(billing.paymentMethod != null);
  }, [billing.paymentMethod]);

  const removeCard = () => {
    dispatch({ type: 'BILLING_UPDATE', payload: { organizationId: organization._id, paymentMethod: null } });
    setShowModal(false);
  }

  return (
    <>
      <SwoopToast id="card-success-toast" success={true} setShow={setShowSuccessToast} show={showSuccessToast} message="Billing Updated" />
      <SwoopToast success={false} setShow={setShowFailureToast} show={showFailureToast} message="Failed to Add Card" />

      <h2>Billing</h2>
      <div className="heading-underline"></div>

      <div className="row">
        <div className="col-sm-6">
          <div className="card w-100">
            <div className="card-body">
              <h5 className="card-title">{organization && organization.credits && organization.credits.toLocaleString()} credits</h5>
              {/* <p className="card-text">{organization && organization.credits && (organization.credits * costPerCredit).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}</p> */}
              <p className="card-text text-secondary">
                To add more credits, click the Recharge button below.
              </p>
              <br />
              <button className="btn btn-primary" onClick={(e) => { e.preventDefault(); setShowRechargeModal(true); }} disabled={!canRecharge}>Recharge</button>
            </div>
          </div>
          <br></br>
        </div>
        <div className="col-12 col-md-5 mb-5 mb-md-0">
          <div className="w-md-80 contact-us-wrapper">
            <h5>Questions about billing?</h5>
            <p className="subtext">
              The Swoop team is here to provide you with the support you need and can create a custom plan for you for the future.
            </p>
            <br />
            <a href="https://swoopnow.com/support/">Contact Support</a>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-6">
          <div className="card fluid w-100">
            <div className="card-body">
              <h5 className="card-title">Credit Card Information</h5>
              {!billing.paymentMethod &&
                <>
                  <p className="card-text text-secondary">
                    Please enter your credit card information below.
                  </p>
                  <br />
                  <CheckoutForm />
                </>
              }

              {billing.paymentMethod &&
                <>
                  <p className="card-text text-secondary">
                    Recharge your credits with the card on file
                  </p>
                  <br />
                  <div className="row">
                    <div className="col-sm-7">
                      <p className="card-text">
                        xxxx xxxx xxxx xxxx {billing.paymentMethod.card.last4}
                      </p>
                    </div>
                    <div className="col-sm-2">
                      <p className="card-text">
                        {billing.paymentMethod.card.exp_month}/{billing.paymentMethod.card.exp_year}
                      </p>
                    </div>
                    <div className="col-sm-3 text-right">
                      <p className="card-text">
                        <a onClick={() => setShowModal(true)}>Remove</a>
                      </p>
                    </div>
                  </div>
                </>
              }
            </div>
          </div>
        </div>
      </div>

      <br />

      {billing.charges && billing.charges.length > 0 &&
        <>
          <div className="row">
            <div className="col-sm-9">
              <div className="card fluid w-100">
                <div className="card-body">
                  <h5 className="card-title">Payment History</h5>

                  <Table>
                    <thead borderless>
                      <tr>
                        <th>Date</th>
                        <th>Amount</th>
                        <th>Credits</th>
                        <th>Receipt</th>
                      </tr>
                    </thead>
                    <tbody>
                      {billing.charges.map((charge, index) => (
                        <tr key={index}>
                          <td>{new Date(charge.created * 1000).toLocaleDateString("en-US")}</td>
                          <td>{(charge.amount / 100).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          })}</td>
                          <td>{(charge.amount / .80).toLocaleString()}</td>
                          <td><a href={charge.receipt_url} target='_BLANK'>View Receipt</a></td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                </div>
              </div>

            </div>
          </div>
        </>
      }

      <ConfirmModal
        id="confirm-modal"
        show={showModal}
        onCancel={() => setShowModal(false)}
        onConfirm={removeCard}
        title="Remove Card?"
        message={<span>Are you sure you want remove this credit card?</span>}
        ok="Yes"
      />

      <RechargeModal
        id="recharge-modal"
        show={showRechargeModal}
        onCancel={() => setShowRechargeModal(false)}
        onConfirm={() => { setShowMessageModal(true); setShowRechargeModal(false); }}
        key="recharge-modal"
      />

      <ConfirmModal
        id="message-modal"
        show={showMessageModal}
        onCancel={() => setShowMessageModal(false)}
        onConfirm={() => {
          // dispatch({ type: "BILLING_GET", payload: {organizationId: organization._id }});
          dispatch({ type: "FETCH_ORGANIZATIONS" });
          setShowMessageModal(false)
        }}
        cancel=""
        title="Payment Successful"
        message={<span>Your payment was successful. Please allow 1-2 minutes for credits to be applied.</span>}
        ok="OK"
      />

      {/* <div className="row">
        <div className="col-12 col-md-7">
          <div className="mb-4pt5">
            <h5>During our beta period all Swoop Services are free.</h5>
            <p className="subtext">
              We will contact you for your first bill to prevent any unexpected disruptions to your service
            </p>
          </div>
          <div className="mb-4pt5">
            <h5>Our Starter Plan is free.</h5>
            <p className="subtext">
              Unlimited first factor authentications with a maximum of 50 MFA attempts per month.
            </p>
          </div>
          <div className="mb-4pt5">
            <h5>Our Premium Plan is currently free until June 1, 2021.</h5>
            <p className="subtext">
              Unlimited first factor authentications with a maximum of 1000 MFA attempts per month.
            </p>
          </div>
        </div>
        
      </div> */}
    </>
  )
}

export default Billing;