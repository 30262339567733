import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const useCreateIdentity = () => {
  const user = useSelector(state => state.auth.user || {});

  useEffect(() => {
    mixpanel.identify(user._id);
    mixpanel.people.set({ $email: user.email });
  }, [user])
}

export const useUpdateUser = () => {
  const user = useSelector(state => state.auth.user || {});
  mixpanel.identify(user._id);

  useEffect(() => {
    if(user.firstName || user.lastName) {
      mixpanel.people.set({ $name: `${user.firstName} ${user.lastName}`})
    }
  }, [user.firstName, user.lastName])

  useEffect(() => {
    if(user.companySize){
      mixpanel.people.set({ 'Company Size': user.companySize })
    }
  }, [user.companySize])

  useEffect(() => {
    if(user.phoneNumber){
      mixpanel.people.set({ 'Phone Number': user.phoneNumber })
    }
  }, [user.phoneNumber])
}

export const useInitializePropertyInfo = () => {
  const user = useSelector(state => state.auth.user || {});
  const properties = useSelector(state => state.dashboard.properties || []);
  
  useEffect(() => {
    mixpanel.identify(user._id);
    mixpanel.people.set({ 'Properties': properties.map(property => [property.name, property.url]) })
    mixpanel.people.set({ 'Total Properties': properties.length })
    mixpanel.people.set({ 'Total MFA Enabled': properties.reduce(
      (memo, property) => property.mfa && property.mfa.enabled ? memo + 1 : memo,
      0 
    )})
    mixpanel.people.set({ 'Total Magic Message Enabled': properties.reduce(
      (memo, property) => property.settings && property.settings.MAGIC_FLOW === "MAGIC_MESSAGE_MAGIC_LINK" ? memo + 1 : memo,
      0 
    )})
    mixpanel.people.set({ 'Total Magic Link Enabled': properties.reduce(
      (memo, property) => (property.settings && property.settings.MAGIC_FLOW === "MAGIC_LINK") || 
      property.settings && !property.settings.MAGIC_FLOW ? 
      memo + 1 : memo,
      0 
    )})
    mixpanel.people.set({ 'Total Magic Link + Message Enabled': properties.reduce(
      (memo, property) => property.settings && property.settings.MAGIC_FLOW === "MAGIC_LINK_MAGIC_MESSAGE" ? memo + 1 : memo,
      0 
    )})
  }, [])
}

