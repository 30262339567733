import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Navbar } from "react-bootstrap";

const AlertBanner = ({message, icon}) => {  
  icon = icon || "fa fa-exclamation-triangle";

  return (
    <>
      <div className="alert-banner">
          <div><i className={icon} /> {message}</div>        
      </div>
    </>
  );
}

export default AlertBanner;
