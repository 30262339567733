export const trackedBrandingElements = {
  primaryColor: {
    id: "primary-color-input",
    eventName: "Click Change Primary Color",
    properties: {
      View: "Branding"
    }
  },
  textColor: {
    id: "text-color-input",
    eventName: "Click Change Text Color",
    properties: {
      View: "Branding"
    }
  },
  uploadLogo: {
    id: "upload-logo-button",
    eventName: "Click Upload Logo",
    properties: {
      View: "Branding"
    }
  },
  updateBranding: {
    id: "update-branding",
    eventName: "Change Branding",
    properties: {
      Type: "CRUD",
      View: "Branding"
    }
  }
}

export const trackedMfaElements = {
  mfaEnabled: {
    id: "mfa-enable-switch",
    eventName: "Click Change MFA Enabled",
    properties: {
      View: "MFA"
    }
  },
  selectPinPad: {
    id: "pin-pad-button",
    eventName: "Click Pin Pad MFA",
    properties: {
      View: "MFA"
    }
  },
  selectSmsText: {
    id: "twilio-sms-button",
    eventName: "Click SMS Text MFA",
    properties: {
      View: "MFA"
    }
  }
}

export const trackedAuthFlowElements = {
  magicMessage: {
    id: "select-magic-message",
    eventName: "Click Select Magic Message",
    properties: {
      View: "Auth Flow"
    }
  },
  magicLink: {
    id: "select-magic-link",
    eventName: "Click Select Magic Link",
    properties: {
      View: "Auth Flow"
    }
  },
  magicLinkPlusMessage: {
    id: "select-magic-link-plus-message",
    eventName: "Click Select Magic Link and Magic Message",
    properties: {
      View: "Auth Flow"
    }
  }
}