import React from 'react';
import { useRouteMatch, useLocation } from "react-router-dom";
import { ListGroup } from "react-bootstrap";
import { isActive } from 'utils/styleGenerators';
import slayer from 'assets/images/slayer-icon-bw.svg';
import slayerFilled from 'assets/images/slayer-icon-filled.svg';

const UserSubNav = () => {
  const match = useRouteMatch();
  const location = useLocation();
  const profilePath = `${match.path}/profile`;
  const billingPath = `${match.path}/billing`;

  return (
    <ListGroup className="subnav">
      <ListGroup.Item action href={`/#${profilePath}`} className={isActive(location, [match.path, profilePath])}>
        <img src={slayer} alt="password-slayer-icon" className="subnav-icon default"/>
        <img src={slayerFilled} alt="password-slayer-icon" className="subnav-icon active"/>
        Profile
      </ListGroup.Item>      
    </ListGroup>
  )
}

export default UserSubNav;