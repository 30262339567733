/**
 * High level router.
 */

import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { useSelector } from "react-redux";

import Logout from "views/layouts/LogoutLayout.jsx";
import AuthLayout from "views/layouts/AuthLayout.jsx";
import AdminLayout from "views/layouts/AdminLayout.jsx";
import AlertBanner from "views/components/AlertBanner";

export const Routes = withRouter( ( { history } ) => {
  let auth = useSelector(state => state.auth);
  let isAuthorized = auth.user != null;
  let selectedOrganization = useSelector(state => state.organizations.selected);
  let minCredits = process.env.REACT_APP_CREDITS_WARNING_AMOUNT || 500;  

  return (
    <>    
    {selectedOrganization && selectedOrganization.credits < minCredits &&
    <AlertBanner
        message={[`Monthly Authorization Credits Low (${selectedOrganization.credits})`, <a href="/#/admin/dashboard/billing">Recharge Now</a>]}
       />    
    }
    <Switch >

      {!isAuthorized ? (
        /* Render auth page when user at `/auth` and not authorized. */
        <AuthLayout />
      ) : (
        /* Otherwise redirect to root page (`/`) */
        <Redirect from="/auth" to='/' />
      )}

      <Route exact path="/" render={props => <AdminLayout {...props} />}/>
      <Route path = "/auth" render = {props => <AuthLayout { ...props } />} />
      <Route path = "/admin" render = { props => <AdminLayout { ...props } />} />
      <Route path = "/logout" render = { props => <Logout { ...props } />} />

      {!isAuthorized ? (
        /* Redirect to `/auth` when user is not authorized */
        <Redirect to="/auth" />
      ) : (
        <AdminLayout />
      )}

    </Switch>
    </>
  );
});
