export function setupAxios(axios, store) {

  axios.interceptors.request.use(
    config => {
      const {
        auth: {
          access_token,
          refresh_token,
          last_refresh_time,
          refreshing
        }
      } = store.getState();

      if(access_token) {
        config.headers.Authorization = `Bearer ${access_token}`;
      }
      
      if( last_refresh_time ){
        let last = new Date(last_refresh_time);
        let minutes = 5.0;
        let diff = new Date - last.getTime()
        diff = diff / (1000 * 60);
        // five minutes
        if(diff > minutes) {
          if(!refreshing) {
            store.dispatch({ type: "REFRESH_AUTH_TOKENS", refresh_token});
          }
        }
      }

      return config;
    },
    err => Promise.reject(err)
  );

  axios.interceptors.response.use((response) => {
      return response
    },
    function(error) {      
      if(error && error.response && error.response.status === 401 ) {
        store.dispatch({ type: "LOGOUT" });
        console.log('unauth')
      }
      throw error;
    })
}

/*  removeStorage: removes a key from localStorage and its sibling expiracy key
    params:
        key <string>     : localStorage key to remove
    returns:
        <boolean> : telling if operation succeeded
 */
export function removeStorage(key) {
  try {
    localStorage.setItem(key, "");
    localStorage.setItem(key + "_expiresIn", "");
  } catch (e) {
    console.log(
      "removeStorage: Error removing key [" +
      key +
      "] from localStorage: " +
      JSON.stringify(e)
    );
    return false;
  }
  return true;
}

/*  getStorage: retrieves a key from localStorage previously set with setStorage().
    params:
        key <string> : localStorage key
    returns:
        <string> : value of localStorage key
        null : in case of expired key or failure
 */
export function getStorage(key) {
  const now = Date.now(); //epoch time, lets deal only with integer
  // set expiration for storage
  let expiresIn = localStorage.getItem(key + "_expiresIn");
  if(expiresIn === undefined || expiresIn === null) {
    expiresIn = 0;
  }

  expiresIn = Math.abs(expiresIn);
  if(expiresIn < now) {
    // Expired
    removeStorage(key);
    return null;
  } else {
    try {
      const value = localStorage.getItem(key);
      return value;
    } catch (e) {
      console.log(
        "getStorage: Error reading key [" +
        key +
        "] from localStorage: " +
        JSON.stringify(e)
      );
      return null;
    }
  }
}
/*  setStorage: writes a key into localStorage setting a expire time
    params:
        key <string>     : localStorage key
        value <string>   : localStorage value
        expires <number> : number of seconds from now to expire the key
    returns:
        <boolean> : telling if operation succeeded
 */
export function setStorage(key, value, expires) {
  if(expires === undefined || expires === null) {
    expires = 24 * 60 * 60 * 30; // default: seconds for 30 days
  }

  const now = Date.now(); //millisecs since epoch time, lets deal only with integer
  const schedule = now + expires * 1000;
  try {
    localStorage.setItem(key, value);
    localStorage.setItem(key + "_expiresIn", schedule);
  } catch (e) {
    console.log(
      "setStorage: Error setting key [" +
      key +
      "] in localStorage: " +
      JSON.stringify(e)
    );
    return false;
  }
  return true;
}
