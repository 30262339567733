import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom'
import { useDispatch } from "react-redux";

const LogoutLayout = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    let swoop = new Swoop(process.env.REACT_APP_SWOOP_SID, {endpoint: 'https://staging.auth.swoop.email'}, (user) => {});
    swoop.out();
    dispatch({ type: "LOGOUT" });
  }, []);
  return (
    <Redirect to='/auth' />
  );
}

export default LogoutLayout;
