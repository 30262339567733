import React from "react";
import { Form } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';

const MfaConfigForm = props => {
  const handlePhoneInputChange = val => {
    props.setMfaSettings({...props.settings, from_phone_number: val});
    if(props.settings.from_phone_number && props.settings.from_phone_number.length > 0){
      props.setIsPhoneValid('valid');
    }
  }
  
  return (
    <>
      {props.config && props.config.map(configItem => {
        if(configItem.type === "text"){
          return (
            <Form.Group key={configItem.key}>
              <Form.Label>{configItem.label}*</Form.Label>
              <Form.Control 
                type="text" 
                value={props.settings[configItem.key]}
                placeholder={configItem.placeholder}
                onChange={e => props.setMfaSettings({...props.settings, [configItem.key]: e.target.value})}
                required
              />
              <Form.Control.Feedback type="invalid">
                {configItem.label} must be provided
              </Form.Control.Feedback>
            </Form.Group>
          )
        } else if(configItem.type === "tel"){
          return (
            <Form.Group key={configItem.key}>
              <Form.Label>{configItem.label}*</Form.Label>
              <PhoneInput
                id="mfa-phone"
                inputProps={{required: true}}
                containerClass={props.isPhoneValid}
                country={'us'}
                value={props.settings[configItem.key]}
                placeholder={configItem.placeholder}
                enableSearch={true}
                onChange={handlePhoneInputChange}
                required
              />
              <Form.Control.Feedback id="mfa-phone-feedback" type="invalid">
                Phone number must be provided
              </Form.Control.Feedback>
            </Form.Group>
          )
        }
      })}
    </>
  )
}

export default MfaConfigForm;
