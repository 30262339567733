const handleLoadToasts = (loadStatus, successCallback, failureCallback) => {
  switch(loadStatus){
    case "success":
      successCallback(true);
      break;
    case "failed":
      failureCallback(true);
      break;
  }
}

const redirectToProperty = (e, property, dispatch, history) => {
  e.preventDefault();
  dispatch({ type: "PROPERTIES_SELECT", payload: property  });
  history.push(`/admin/property`);
}

const redirectToOrganization = (e, organization, dispatch, history) => {
  e.preventDefault();
  dispatch({ type: "ORGANIZATION_SELECT", payload: organization  });
  dispatch({ type: "PROPERTIES_SELECT", payload: null  });
  history.push(`/admin/dashboard`);
}

export { redirectToProperty, handleLoadToasts, redirectToOrganization }