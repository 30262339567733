import React, { useState } from 'react';
import { Form, InputGroup, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import SwoopToast from 'views/components/SwoopToast';
import clipboard from 'assets/images/clipboard-icon.svg';
import copy from 'copy-to-clipboard';

const Credentials = () => {
  const property = useSelector(state => state.dashboard.selectedProperty);
  let admin = property.role === 0 ? true : false;

  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [successToastMessage, setSuccessToastMessage] = useState("");
  const [showFailureToast, setShowFailureToast] = useState(false);
  const [failureToastMessage, setFailureToastMessage] = useState("");

  const copyToClipboard = (elementId, copiedValueName) => {
    const copyValue = document.getElementById(elementId).value;
    const didCopy = copy(copyValue);
    if(didCopy){
      setSuccessToastMessage(`${copiedValueName} copied to clipboard`);
      setShowSuccessToast(true);
    } else {
      setFailureToastMessage(`Failed to copy ${copiedValueName} to clipboard. Check your browser settings.`);
      setShowFailureToast(true);
    }
  }

  return (
    <>
      <SwoopToast id="copy-success-toast" success={true} setShow={setShowSuccessToast} show={showSuccessToast} message={successToastMessage} />
      <SwoopToast id="copy-failure-toast" success={false} setShow={setShowFailureToast} show={showFailureToast} message={failureToastMessage} />
      <h2>Credentials</h2>
      <div className="heading-underline"></div>
      <Form onSubmit={e => { e.preventDefault(); }} className="w-lg-80">
        <Form.Group>
          <Form.Label>Client ID</Form.Label>
          <Form.Text className="subtext">
            A public identifier for your property and application using that property. 
          </Form.Text>
          <InputGroup className="w-lg-75">
            <Form.Control
              id="client-id"
              placeholder="SID"
              type="text"
              name="sid"
              value={property.sid}
              onChange={null}
              readOnly
              />
            <InputGroup.Append>
              <Button data-testid="copy-id" className="align-items-middle img-wrapper"  onClick={() => copyToClipboard('client-id', 'Client ID')}>
                <img src={clipboard} alt="clipboard icon" className="btn-left-img"/>
                <span className="d-none d-md-inline">Copy Client ID</span>
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </Form.Group>
        <Form.Group data-testid="secret" hidden={!admin}>
          <Form.Label>Secret</Form.Label>
          <Form.Text className="subtext">
            A cryptographically secure secret, used in combination with your Client ID to verify end users. 
          </Form.Text>
          <InputGroup className="w-lg-75">
            <Form.Control
              id='secret'
              placeholder="Secret"
              type="text"
              name="secret"
              value={admin ? property.secret.replace(/./g, '*') : ''}
              onChange={null}
              readOnly
              />
              <Form.Control
              id='secret_hidden'              
              type="hidden"
              name="secret_hidden"
              value={admin ? property.secret : ''}              
              readOnly
              />
            <InputGroup.Append>
              <Button data-testid="copy-secret" className="align-items-middle img-wrapper" onClick={() => copyToClipboard('secret_hidden', 'Secret')}>
                <img src={clipboard} alt="clipboard icon" className="btn-left-img"/>
                <span className="d-none d-md-inline">Copy Secret</span>
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </Form.Group>
      </Form>
    </>
  )
}

export default Credentials;