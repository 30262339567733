import React, { useState, useEffect } from 'react'
import { SketchPicker } from 'react-color'
import { Form, Button, Modal, InputGroup } from "react-bootstrap";
import {useSelector, useDispatch} from 'react-redux';
import SwoopToast from 'views/components/SwoopToast';
import { handleLoadToasts } from "utils/eventHandling";
import ReactS3Uploader from 'react-s3-uploader';
import uploadFile from 'assets/images/upload-file.svg';
import MixpanelClicks from 'mixpanel/MixpanelClicks';
import { trackedBrandingElements as trackedElements } from 'mixpanel/trackedElements';

const Branding = (props) => {
  const dispatch = useDispatch()

  let dashboardAPIURL = process.env.REACT_APP_DASHBOARD_API_ENDPOINT;
  const { selectedProperty } = useSelector(state => state.dashboard);
  let admin = selectedProperty.role === 0 ? true : false;
  const loadStatus = useSelector(state => state.dashboard.loadStatuses && state.dashboard.loadStatuses.propertyLoadStatus);
  const [color, setColor] = useState(selectedProperty.settings.PROPERTY_PRIMARY_COLOR);
  const [textColor, setTextColor] = useState(selectedProperty.settings.PROPERTY_PRIMARY_TEXT_COLOR);
  const [logoURL, setLogoURL] = useState(selectedProperty.settings.PROPERTY_LOGO_URL);
  const [colorPicker, setColorPicker] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showUpdateFailure, setShowUpdateFailure] = useState(false);
  const [showUploadFailure, setShowUploadFailure] = useState(false);
  const [uploader, setUploader] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    handleLoadToasts(loadStatus, setShowSuccessToast, setShowUpdateFailure);
  }, [loadStatus]);

  const onUploadFinish = (file) => {
    let url = `${dashboardAPIURL}${file.publicUrl}`;
    setLogoURL(url);
  }

  const getDefaultColor = () => {
    switch(colorPicker){
      case "color":
        return color;
      case "textColor":
        return textColor;
    }
  }

  const handleColorChange = hex => {
    switch(colorPicker){
      case "color":
        setColor(hex);
        break;
      case "textColor":
        setTextColor(hex);
        break;
    }
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();

    dispatch({
      type: "UPDATE_PROPERTY",
      organizationId: selectedProperty.organization,
      propertyId: selectedProperty._id,
      params: { settings: {
        'PROPERTY_PRIMARY_COLOR': color,
        'PROPERTY_PRIMARY_TEXT_COLOR': textColor,
        'PROPERTY_LOGO_URL': logoURL
      }}
    });
  }

  return (
    <>
      {!props.testing && <MixpanelClicks elements={Object.values(trackedElements)} /> }
      <Modal
        id="color-picker-modal"
        className="color-picker-modal"
        backdropClassName="op-0"
        show={!!colorPicker}
        onHide={() => setColorPicker(false)}
      >
        <SketchPicker color={getDefaultColor()} onChange={({ hex }) => handleColorChange(hex)} />
      </Modal>
      <SwoopToast success={true} setShow={setShowSuccessToast} show={showSuccessToast} message="Branding Updated" />
      <SwoopToast success={false} setShow={setShowUpdateFailure} show={showUpdateFailure} message="Failed to Update Branding" />
      <SwoopToast success={false} setShow={setShowUploadFailure} show={showUploadFailure} message="Failed to upload logo" />
      <h2>Branding</h2>
      <div className="heading-underline"></div>
      <div className="row">
        <div className="col-12 col-md-4">
          <Form>
            <Form.Group>
              <Form.Label>
                Primary Color
              </Form.Label>
              <Form.Text className="subtext">
                Used for background color, link states and icons.
              </Form.Text>
              <InputGroup className="separated">
                <InputGroup.Prepend>
                  <div
                    disabled={!admin}
                    data-testid="primary-color-swatch"
                    onClick={() => admin ? setColorPicker("color") : null}
                    style={{ backgroundColor: color}}
                    className="color-swatch attached-item"
                  >
                  </div>
                </InputGroup.Prepend>
                <Form.Control
                  disabled={!admin}
                  data-testid="primary-color-input"
                  id={trackedElements.primaryColor.id}
                  placeholder="RGB"
                  type="text"
                  name="name"
                  value={color}
                  onChange={() => null}
                  onClick={() => admin ? setColorPicker("color") : null}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Primary Text Color
              </Form.Label>
              <Form.Text className="subtext">
                Used as the primary text color for several elements.
              </Form.Text>
              <InputGroup className="separated">
                <InputGroup.Prepend>
                  <div
                  disabled={!admin}
                  data-testid="primary-text-color-swatch"
                  onClick={() => admin ? setColorPicker("textColor") : null}
                  style={{ backgroundColor: textColor}}
                  className="color-swatch attached-item"
                  ></div>
                </InputGroup.Prepend>
                <Form.Control
                  disabled={!admin}
                  data-testid="primary-text-color-input"
                  id={trackedElements.textColor.id}
                  placeholder="RGB"
                  type="text"
                  name="name"
                  value={textColor}
                  onChange={() => null}
                  onClick={() => admin ? setColorPicker("textColor") : null}
                />
              </InputGroup>
            </Form.Group>
          </Form>
        </div>
        <div className="col-12 col-md-4 ml-0 ml-md-5">
          <Form.Group>
            <Form.Label>
              Logo
            </Form.Label>
            <Form.Text className="subtext">
              Upload your logo as a PNG or JPG. Maximum dimensions are 80px high or 350px wide.
            </Form.Text>
            <img data-testid="logo-thumbnail" src={logoURL} className="logo-thumbnail d-block mb-2" />
            <label className="w-100" id={trackedElements.uploadLogo.id}>
              <img src={uploadFile} className="fake-button w-80"/>
              <ReactS3Uploader
                disabled={!admin}
                signingUrl="/s3/sign"
                onFinish={onUploadFinish}
                onProgress={progress => setUploadProgress(progress)}
                onError={() => setShowUploadFailure(true)}
                signingUrlMethod="GET"
                accept="image/*"
                s3path={`properties/${selectedProperty._id}/logos/`}
                uploadRequestHeaders={{ 'x-amz-acl': 'public-read' }}  // this is the default
                contentDisposition="auto"
                scrubFilename={(filename) => filename.replace(/[^\w\d_\-.]+/ig, '')}
                server={dashboardAPIURL}
                ref={uploader => { setUploader(uploader) }}
                autoUpload={true}
                className="d-none"
              />
            </label>
          </Form.Group>
        </div>
      </div>
      <div className="row mt-n1 mx-0 mb-4 mb-lg-0">
        <Button data-testid="update-branding-button" id={trackedElements.updateBranding.id} type='submit' onClick={handleFormSubmit} disabled={!admin}>
          Update Branding
        </Button>
      </div>
    </>
  );
}

export default Branding;
