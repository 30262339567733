import mixpanel from 'mixpanel-browser';
import { useTrackClicks } from 'mixpanel/eventHooks';
import { useUpdateMfaInfo, useUpdateAuthFlow, useCreateProperty, useDeleteProperty, useUpdateProperty } from 'mixpanel/crudHooks';
import { useSelector } from 'react-redux';

const MixpanelClicks = props => {
  const user = useSelector(state => state.auth.user || {});
  mixpanel.identify(user._id);

  useTrackClicks(props.elements);

  switch(props.view){
    case "MFA":
      useUpdateMfaInfo();
      break;
    case "AuthFlow":
      useUpdateAuthFlow();
      break;
    case "Settings":
      //Create and Delete property are currently being tracked on the API side
      useUpdateProperty();
      break;
  }
  
  return null
}

export default MixpanelClicks