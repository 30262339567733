import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import AdminNavbar from "views/components/nav/AdminNavbar.jsx";
import PropertyLayout from 'views/layouts/PropertyLayout';
import OrganizationLayout from 'views/layouts/OrganizationLayout';
import NewProperty from 'views/pages/properties/NewProperty.jsx';
import UserLayout from 'views/layouts/UserLayout';
import Footer from "views/components/Footer.jsx";
import { Container } from "react-bootstrap";
import { filter } from "lodash-es";
import NewOrganization from "../pages/organizations/NewOrganization";
import AlertBanner from "views/components/AlertBanner";

const AdminLayout = props => {
  const properties = useSelector(state => state.dashboard.properties) || [];
  const selectedOrganization = useSelector(state => state.organizations.selected);
  const [filteredProperties, setFilteredProperties] = useState([...properties]);
  const dispatch = useDispatch();
  const match = useRouteMatch();

  useEffect(() => {
    dispatch({ type: "FETCH_ORGANIZATIONS" });
  }, [])

  useEffect(() => {
    if(selectedOrganization) {
      let filtered = properties.filter(property => property.organization === selectedOrganization._id);
      setFilteredProperties(filtered);
    }
  }, [properties, selectedOrganization])

  return (
    <>      
      <AdminNavbar
        filteredProperties={filteredProperties}
        setFilteredProperties={setFilteredProperties}
        {...props}
      />
      <Container fluid>
        <Switch>

          <Route exact path={`${match.path}/`}>
            <Redirect to="/admin/dashboard" />
          </Route>
          <Route path={`${match.path}/dashboard`} render={props =>
            <OrganizationLayout {...props} filteredProperties={filteredProperties} setFilteredProperties={setFilteredProperties} />}
          />
          <Route path={`${match.path}/property`} render={props => <PropertyLayout {...props} />} />
          <Route path={`${match.path}/new-property`} render={props => <NewProperty {...props} />} />
          <Route path={`${match.path}/new-organization`} render={props => <NewOrganization {...props} />} />
          <Route path={`${match.path}/user`} render={props => <UserLayout {...props} />} />
        </Switch>
      </Container>
      <Footer />
    </>
  );
}

export default AdminLayout;
