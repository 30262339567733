import { call, put, takeLatest } from "redux-saga/effects";
import axios from 'axios';
import { errorHandler } from 'utils/sagas';

const DASHBOARD_API_BASE_URL = process.env.REACT_APP_DASHBOARD_API_ENDPOINT;
const DASHBOARD_API_ENDPOINT = `${DASHBOARD_API_BASE_URL}/api`;

export function* getBilling(action){
  let url = `${DASHBOARD_API_ENDPOINT}/organizations/${action.payload.organizationId}/billing`;
  try {
    const response = yield call(axios.get, url);        
    if(response.data) {
      yield put({ type: 'BILLING_FULFILL', payload: response.data.billing });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* updateBilling(action){  
  let url = `${DASHBOARD_API_ENDPOINT}/organizations/${action.payload.organizationId}/billing`;
  const body = {
    paymentMethod: action.payload.paymentMethod,
  }    
  let response = yield call(axios.put, url, body);    
  yield put({ type: 'BILLING_GET', payload: { organizationId: action.payload.organizationId } });
}

export function* createPaymentIntent(action){
  let url = `${DASHBOARD_API_ENDPOINT}/organizations/${action.payload.organizationId}/billing/stripe-payment-intent`;  
  try {
    const body = {      
      amount: action.payload.amount,
    }
    const { data } = yield call(axios.post, url, body);
    let intent = data.intent;
    yield put({ type: 'BILLING_PAYMENT_INTENT_FULFILL', payload: {intent} });
  } catch (error) {
    console.log(error);
    return null;
  }
}

export function* billingSaga() {  
  yield takeLatest("BILLING_GET", errorHandler(getBilling, "UPDATE_LOAD_STATUS", "billingGetStatus"));
  yield takeLatest("BILLING_UPDATE", errorHandler(updateBilling, "UPDATE_LOAD_STATUS", "billingUpdateStatus"));
  yield takeLatest("BILLING_CREATE_PAYMENT_INTENT", errorHandler(createPaymentIntent, "UPDATE_LOAD_STATUS", "billingCreatePaymentIntentStatus"));
}
