import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Table, ProgressBar } from 'react-bootstrap';
import {latLngBounds} from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster';

const CustomLayer = (props) => {
    const groupRef = useRef(null);
    const { markers } = props;
    const map = useMap();    

    useEffect(() => {
        if (markers && markers.length > 0) {            
            let markerBounds = latLngBounds([]);
            markers.forEach(marker => {                
                markerBounds.extend(marker.props.position)
            })
            map.fitBounds(markerBounds)
        }
    }, [markers]);

    return (
        <MarkerClusterGroup ref={groupRef} showCoverageOnHover={false}>
            {markers.map((marker, index) => (
                marker
            ))}
        </MarkerClusterGroup>
    );
}

const Analytics = (props) => {
    const property = useSelector(state => state.dashboard.selectedProperty || {});
    const dispatch = useDispatch();

    // Analytics
    const authenticationsMonthly = useSelector(state => state.analytics.authentications.monthly || 0);
    const usersMonthly = useSelector(state => state.analytics.users.monthly || 0);
    const usersTotal = useSelector(state => state.analytics.users.total || 0);
    const countries = useSelector(state => state.analytics.authentications.countries || []);
    const locations = useSelector(state => state.analytics.authentications.locations || []);
    const [markers, setMarkers] = useState([]);

    // Load statuses
    const loadingAuthenticationsMonthly = useSelector(state => state.dashboard.loadStatuses && state.dashboard.loadStatuses.getAuthenticationsMonthlyStatus);
    const loadingUsersMonthly = useSelector(state => state.dashboard.loadStatuses && state.dashboard.loadStatuses.getActiveUsersMonthlyStatus);
    const loadingUsersTotal = useSelector(state => state.dashboard.loadStatuses && state.dashboard.loadStatuses.getTotalUsersStatus);
    const loadingCountries = useSelector(state => state.dashboard.loadStatuses && state.dashboard.loadStatuses.getAuthenticationsLocationsStatus);

    const position = [51.505, -0.09];

    const dispatchAll = () => {
        dispatch({
            type: "ANALYTICS_GET_AUTHENTICATIONS_MONTHLY",
            payload: {
                propertyId: property._id,
            }
        });
        dispatch({
            type: "ANALYTICS_GET_ACTIVE_USERS_MONTHLY",
            payload: {
                propertyId: property._id,
            }
        });
        dispatch({
            type: "ANALYTICS_GET_TOTAL_USERS",
            payload: {
                propertyId: property._id,
            }
        });

        dispatch({
            type: "ANALYTICS_GET_AUTHENTICATIONS_LOCATIONS",
            payload: {
                propertyId: property._id,
            }
        });     
    }

    useEffect(() => {        
        dispatchAll();
    }, []);

    useEffect(() => {        
        dispatchAll();
    }, [property]);

    useEffect(() => {
        // Populate markers from locations
        let _markers = [];
        locations.forEach((location, index) => {
            _markers.push(
                (
                    <Marker key={index} position={[location.latitude, location.longitude]}>
                        <Popup>
                            <div><span>{location.city}, {location.region_name}</span></div>
                            <div><span>{location.location.country_flag_emoji} {location.country_name}</span></div>
                        </Popup>
                    </Marker>
                )
            );
        });
        setMarkers(_markers);
    }, [locations]);

    return (
        <>
            <h2>Analytics</h2>
            <div className="heading-underline"></div>
            <div className="row p-lg-6">

                <div name="property-card" key='3' className="col-12 col-md-3 card-wrapper">
                    <Card className="analytics-card" id={`analytics-monthly-users-${property._id}`}>
                        <Card.Header>
                            {/* Center Align */}
                            <div className="text-center">
                                <div>Total Users</div>
                            </div>
                        </Card.Header>
                        <Card.Body className="text-center">
                            {/* Centered Large Number */}
                            <div className="text-center">
                                <h1 className="large-number">{usersTotal.toLocaleString()}</h1>
                                <h3 className="subtext">Total</h3>
                            </div>
                        </Card.Body>
                    </Card>
                </div>

                <div name="property-card" key='2' className="col-12 col-md-3 card-wrapper">
                    <Card className="analytics-card" id={`analytics-monthly-users-${property._id}`}>
                        <Card.Header>
                            {/* Center Align */}
                            <div className="text-center">
                                <div>Active Users</div>
                            </div>
                        </Card.Header>
                        <Card.Body className="text-center">
                            {/* Centered Large Number */}
                            <div className="text-center">
                                <h1 className="large-number">{usersMonthly.toLocaleString()}</h1>
                                <h3 className="subtext">Monthly</h3>
                            </div>
                        </Card.Body>
                    </Card>
                </div>


                <div name="property-card" key='1' className="col-12 col-md-3 card-wrapper">
                    <Card className="analytics-card" id={`analytics-monthly-users-${property._id}`}>
                        <Card.Header>
                            {/* Center Align */}
                            <div className="text-center">
                                <div>Authentications</div>
                            </div>
                        </Card.Header>
                        <Card.Body className="text-center">
                            {/* Centered Large Number */}
                            <div className="text-center">
                                <h1 className="large-number">{authenticationsMonthly.toLocaleString()}</h1>
                                <h3 className="subtext">Monthly</h3>
                            </div>
                        </Card.Body>
                    </Card>
                </div>

                <div name="property-card" key='1' className="col-12 col-md-3 card-wrapper">
                    <Card className="analytics-card" id={`analytics-monthly-users-${property._id}`}>
                        <Card.Header>
                            {/* Center Align */}
                            <div className="text-center">
                                <div>Authentications/User</div>
                            </div>
                        </Card.Header>
                        <Card.Body className="text-center">
                            {/* Centered Large Number */}
                            <div className="text-center">
                                <h1 className="large-number">{usersMonthly > 0 ? (Math.ceil((authenticationsMonthly/usersMonthly)*100) / 100).toLocaleString() : 0}</h1>
                                <h3 className="subtext">Monthly</h3>
                            </div>
                        </Card.Body>
                    </Card>
                </div>

                <div name="property-card" key='1' className="col-12 col-md-12 card-wrapper">
                    <Card className="analytics-card fluid" id={`analytics-monthly-users-${property._id}`}>
                        <Card.Header>
                            {/* Center Align */}
                            <div className="text-center">
                                <div>Authentications By Country</div>
                            </div>
                        </Card.Header>
                        <Card.Body className="text-center">
                            <MapContainer center={position} zoom={13} scrollWheelZoom={false} style={{ height: "600px" }}>
                                <TileLayer
                                    attribution='&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>'
                                    url="https://api.mapbox.com/styles/v1/brandonswoop/cl0h09805001a14phj2n0j5u1/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYnJhbmRvbnN3b29wIiwiYSI6ImNsMGgwMnhjbTAzdzczaW11NnAya3g1Y3cifQ.drwyMDzQZvlX7qkQg_7PFA"
                                />
                                <CustomLayer markers={markers} />
                            </MapContainer>

                            <Table className="analytics" borderless>
                                <tbody>
                                    {countries.slice(0,10).map((country, index) => (
                                        <tr key={index}>                                            
                                            <td align='right'>{country.flag} {country.country}</td>
                                            <td>
                                                <ProgressBar variant="success" now={(country.count/authenticationsMonthly)*100} label={country.count} />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                        </Card.Body>
                    </Card>
                </div>

            </div>
        </>
    )
}

export default Analytics;
