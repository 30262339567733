import React, { useState, useEffect } from "react";
import { Form, Button, Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import skull from 'assets/images/skull-icon.svg';
import axios from "axios";

const DASHBOARD_API_BASE_URL = process.env.REACT_APP_DASHBOARD_API_ENDPOINT;
const DASHBOARD_API_ENDPOINT = `${DASHBOARD_API_BASE_URL}/api`;

const OrganizationTransferModal = ({ show, onHide, property }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [transferId, setTransferId] = useState("");
    const [loadState, setLoadState] = useState("DEFAULT");
    const [organization, setOrganization] = useState(null);

    useEffect(() => {
        setTransferId("");
        setLoadState("DEFAULT");
        setOrganization(null);
    }, [show])

    const handleTransferSubmit = async (e) => {
        e.preventDefault();
        // fetch organization from the api
        if (loadState === "DEFAULT") {
            try {
                setLoadState("ORG_LOADING");
                const { data } = await axios.get(`${DASHBOARD_API_ENDPOINT}/organizations/${transferId}`);
                setLoadState("ORG_LOADED");
                setOrganization(data);
                console.log(data);
            } catch (error) {
                console.log(error);
            }
        } else if (loadState === "ORG_LOADED") {            
            setLoadState("TRASNFER_SUBMITTING");
            try {
                const { data } = await axios.post(`${DASHBOARD_API_ENDPOINT}/organizations/${property.organization}/properties/${property._id}/transfer`, {organizationId: organization._id});
                console.log(data)
                setLoadState("SUBMITTED");                
                history.push("/admin/dashboard");
                dispatch({ type: "PROPERTIES_SELECT", payload: null });
            } catch (error) {
                console.log(error);
            }
        }
    }


    return (
        <Modal centered show={show} onHide={onHide}>
            <Modal.Header closeButton className="mt-2 justify-content-center">
                <img src={skull} alt="skull-icon" className="w-10 t-n110" />
            </Modal.Header>
            <Modal.Body className="pt-0">
                <Form noValidate onSubmit={handleTransferSubmit}>
                    <Form.Group>
                        <h2>Transfer App</h2>
                        <Form.Group className="mb-3" controlId="">
                            <Form.Text className="subtext">
                                Transferring your app is irreversible. Enter the ID of the team you want to transfer your app to:
                            </Form.Text>
                            <Form.Control
                                data-testid="trasnfer-id"
                                placeholder="Team ID"
                                name="transfer_id"
                                type="text"
                                onChange={e => setTransferId(e.target.value)}
                                isInvalid={transferId.length !== 24 && transferId.length !== 0}
                            />
                        </Form.Group>
                        {organization && (
                            <Form.Group className="mb-3" controlId="">
                                <div className="heading-underline"></div>
                                <Form.Text className="text-secondary">
                                    <h5><strong>Team: </strong>{organization.name}</h5><br></br>
                                </Form.Text>
                                <Form.Text className="subtext">
                                    By clicking "Next", you confirm that <strong>{organization.name}</strong> is the correct team to transfer your app to.
                                    <span className="text-danger">&nbsp;This action is irreversible.</span>
                                </Form.Text>
                            </Form.Group>
                        )}
                        <Form.Control.Feedback type="invalid">
                            The ID is invalid
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="">
                        <Button className="button-primary"
                            data-testid="transfer-property"
                            onClick={handleTransferSubmit}
                            disabled={transferId.length !== 24 || loadState === "ORG_LOADING" || loadState === "TRASNFER_SUBMITTING"}>
                            {loadState === "ORG_LOADING" || loadState === "TRASNFER_SUBMITTING" ? <><Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            /> Loading...</> : "Next"}
                        </Button>
                    </Form.Group>
                </Form>
            </Modal.Body>
        </Modal>)
}
export default OrganizationTransferModal;
