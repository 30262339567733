import { call, put, takeLatest, select } from "redux-saga/effects";
import axios from "axios";
import { errorHandler } from 'utils/sagas';

const DASHBOARD_API_BASE_URL = process.env.REACT_APP_DASHBOARD_API_ENDPOINT;
const DASHBOARD_API_ENDPOINT = `${DASHBOARD_API_BASE_URL}/api`;

//property worker sagas
export function* fetchAllProperties(){
  try {
    let url = `${DASHBOARD_API_ENDPOINT}/properties/all`;
    const { data: properties } = yield call(axios.get, url);
    yield put({ type: "PROPERTIES_SHOW", payload: properties });    
    
    // Reload the selected property
    let selectedProperty = yield select(state => state.dashboard.selectedProperty);
    if(selectedProperty) {
      let updatedSelectedProperty = properties.properties.find(property => property._id === selectedProperty._id);
      yield put({ type: "PROPERTIES_SELECT", payload: updatedSelectedProperty });
    }

  } catch (error) {
    console.log(error)
  }
}

export function* createProperty(action){
  let url = `${DASHBOARD_API_ENDPOINT}/organizations/${action.organizationId}/properties`;
  const { data: property } = yield call(axios.post, url, action.params);
  // the attribute role: 0 is added so an additional call to the api is not needed to check if
  // the user has admin privileges for this property. Temp fix until API refactor.
  // the mfa attribute is added so that property.mfa doesn't result in undefined and break the mfa view code
  // this is a temporary fix until the mfa model is refactored on the api side
  yield put({ type: "PROPERTIES_FETCH_ALL" });
  yield put({ type: "PROPERTIES_SELECT", payload: { ...property, role: 0, mfa: property.mfa || {} } });
  yield call(action.history.push, '/admin/property');
}

export function* updateProperty(action){
    let url = `${DASHBOARD_API_ENDPOINT}/organizations/${action.organizationId}/properties/${action.propertyId}`;
    const { data: updatedProperty } = yield call(axios.put, url, action.params);    

    // the attribute role: 0 is added so an additional call to the api is not needed to check if
    // the user has admin privileges for this property. Temp fix until API refactor.    
    yield put({ type: "PROPERTIES_FETCH_ALL" });
    yield put({ type: "PROPERTIES_SELECT", payload: { ...updatedProperty, role: 0 } })
}

export function* deleteProperty(action){
  try {
    let url = `${DASHBOARD_API_ENDPOINT}/organizations/${action.organizationId}/properties/${action.propertyId}`;
    yield call(axios.delete, url );
    yield call(action.history.push, '/admin/dashboard');
    yield put({ type: "PROPERTIES_SELECT", payload: null });    
  } catch (error) {
    console.log(error);
  }
}

export function* propertySaga() {
  yield takeLatest("PROPERTIES_FETCH_ALL", fetchAllProperties);
  yield takeLatest("CREATE_PROPERTY", errorHandler(createProperty, "UPDATE_LOAD_STATUS", 'propertyCreateStatus'));
  yield takeLatest("UPDATE_PROPERTY", errorHandler(updateProperty, "UPDATE_LOAD_STATUS", 'propertyLoadStatus'));
  yield takeLatest("DELETE_PROPERTY", errorHandler(deleteProperty, "UPDATE_LOAD_STATUS", 'propertyDeleteStatus'));
}
