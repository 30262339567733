import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import addIcon from 'assets/images/add-icon.svg';

const NewOrganization = () => {
  const [show, setShow] = useState(true);
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState(""); 
    
  const dispatch = useDispatch();
  const history = useHistory();

  const handleFormSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setValidated(true);

    if(name.length > 0) {
      dispatch({ 
        type: "CREATE_ORGANIZATION",          
        params: { name },
        history
      });
    }
  }

  const handleHide = () => {
    setShow(false);
    history.goBack();
  }

  return (
    <Modal show={show} onHide={handleHide}>
      <Modal.Header closeButton className="justify-content-center">
        <img src={addIcon} alt="add-icon" className="w-10 t-n20 d-none d-md-block"/>
        <div className="row justify-content-center text-center w-100 mt-2 mt-md-5">
          <div className="col-12 col-md-9">
            <h3>Add New Team</h3>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
          <Form.Group>
            <Form.Label>Team Name *</Form.Label>
            <Form.Text className="subtext">
              The name of your team. You can change this later.
            </Form.Text>
            <Form.Control
              name="name"
              data-testid = "organization-name"
              value={name}
              placeholder="Enter your team name"
              type="text"
              required
              onChange={e => setName(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Please give your team a name
            </Form.Control.Feedback>
          </Form.Group>          
          <Button data-testid="submit-form" type='submit'>
            Add It!
          </Button>
        </Form>        
      </Modal.Body>
    </Modal>
  )
}

export default NewOrganization;

