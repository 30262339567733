import React from "react";
import { Navbar, Nav } from "react-bootstrap";
// import logo from "assets/images/1-Click-Login_2color.svg"

const Footer = () => {
    return (
      <Navbar className="footer">
        <Nav className="flex-column flex-md-row">
          <Nav.Item>
            <Nav.Link href="https://swoopnow.com/">
              {/* <img src={logo} alt="swoop logo" className="mr-2"/> */}
              <span>Swoopnow.com</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="https://docs.swoopnow.com/">Documentation</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="https://swoopnow.com/support/" >Support</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="https://swoopnow.com/privacy-policy/">Privacy</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="https://swoopnow.com/terms-of-service/">Terms</Nav.Link>
          </Nav.Item>
          <Nav.Item className="align-self-md-right ml-2 ml-md-0">
              &copy; {1900 + new Date().getYear()}{" "}Swoop In Technologies LLC
          </Nav.Item>
        </Nav>
      </Navbar>
    );
}
export default Footer;
