const initialOrgState = {
  all: [],
  selected: {},
  personal: {},
  loadStatus: null,
  admins: []
};

const organizationsReducer = (state = initialOrgState, action) => {
  switch (action.type) {
    case "ORGANIZATIONS_SHOW": {      
      return { ...state, all: action.payload.organizations, personal: action.payload.personal };
    }

    case "ORGANIZATION_SELECT": {
      return { ...state, selected: action.payload };
    }

    case "UPDATE_ORGANIZATION_LOAD_STATUS": {
      return { ...state, loadStatus: action.payload }
    }
    case "LOGOUT": {
      return initialOrgState;
    }

    /* Organization Admins */
    case "FULFILL_ORGANIZATION_ADMINS": {
      return { ...state, admins: action.payload }
      break;
    }

    case "FULFILL_DELETED_ORGANIZATION_ADMIN": {
      const updatedOrganizationAdmins = state.admins.filter(admin => admin._id !== action.payload);      
      return {
        ...state,
        admins: updatedOrganizationAdmins
      }
    }

    default:
      return state;
  }
}

export default organizationsReducer
