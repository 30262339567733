import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Settings from '../pages/organizations/Settings';
import Billing from '../pages/organizations/Billing';
import Permissions from '../pages/organizations/Permissions';
import OrganizationSubNav from '../components/nav/OrganizationSubNav';
import Dashboard from 'views/pages/organizations/Dashboard';

const OrganizationLayout = () => {
  const organization = useSelector(state => state.organizations.selected);
  const match = useRouteMatch();

  return (
    <>
      <div className="row title-banner d-lg-none">
        <OrganizationSubNav mobile organization={organization} />
      </div>
      {/* <div className="row title-banner d-none d-lg-flex">{organization.name}</div> */}
      <div className="row p-lg-5">
        <div className="d-none d-lg-flex col col-lg-3">
          <OrganizationSubNav desktop />
        </div>
        <div className="col col-12 col-lg-9 pt-3 pt-lg-0">
          <Switch>
          <Route exact path={`/`} render={props => <Dashboard {...props} />}/>       
            <Route exact path={`${match.path}/`} render={props => <Dashboard {...props} />}/>       
            <Route exact path={`${match.path}/settings`} render={props => <Settings {...props} />}/>       
            <Route exact path={`${match.path}/billing`} render={props => <Billing {...props} />}/>       
            <Route exact path={`${match.path}/permissions`} render={props => <Permissions {...props} />}/>                   
          </Switch>
        </div>
      </div>
    </>
  )
}

export default OrganizationLayout;
