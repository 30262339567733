import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Form, Button } from "react-bootstrap";
import SwoopToast from 'views/components/SwoopToast';
import { handleLoadToasts } from "utils/eventHandling";
import magicLinkPlusMessage from 'assets/images/magic-link-plus-message.svg'
import magicMessage from 'assets/images/magic-message.svg'
import magicLink from 'assets/images/magic-link.svg'
import MixpanelClicks from 'mixpanel/MixpanelClicks';
import { trackedAuthFlowElements as trackedElements } from 'mixpanel/trackedElements';

const AuthFlow = (props) => {
  const property = useSelector(state => state.dashboard.selectedProperty || {});
  let admin = property.role === 0 ? true : false;
  const loadStatus = useSelector(state => state.dashboard.loadStatuses && state.dashboard.loadStatuses.propertyLoadStatus);
  const [settings, setSettings] = useState(property.settings || {});
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showFailureToast, setShowFailureToast] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    handleLoadToasts(loadStatus, setShowSuccessToast, setShowFailureToast);
  }, [loadStatus]);

  const handleSubmit = e => {
    e.preventDefault();

    dispatch({
      type: "UPDATE_PROPERTY",
      organizationId: property.organization,
      propertyId: property._id,
      params: { settings }
    });
  }

  return (
    <>
      {!props.testing && <MixpanelClicks elements={Object.values(trackedElements)} view="AuthFlow"/>}
      <SwoopToast id="auth-flow-success-toast" success={true} setShow={setShowSuccessToast} show={showSuccessToast} message="Settings Updated" />
      <SwoopToast id="auth-flow-failure-toast" success={false} setShow={setShowFailureToast} show={showFailureToast} message="Failed to Update Settings" />
      <h2>Webhooks</h2>
      <div className="heading-underline"></div>
      <Form className="w-md-60 mb-5 mb-md-0" onSubmit={handleSubmit}>
      <Form.Group className="mt-5" disabled={!admin}>
          <Form.Label>Webhook URL</Form.Label>
          <Form.Text>If present, 1-Click Login will attempt to send Webhook events to the following URL. Check out the <a target='_BLANK' href="https://docs.swoopnow.com/docs/webhooks">1-Click Login Documentation</a> for implementation details. </Form.Text>                     
            <Form.Control
              disabled={!admin}
              data-testid="webhook-url"
              placeholder="Enter the URL to send Webhook events to"
              type="text"
              name="webhookUrl"
              value={settings['WEBHOOK_URL']}
              onChange={e => {
                let value = e.target.value;
                setSettings({ ...settings,
                  'WEBHOOK_URL': value
                });
              }}
              />             
        </Form.Group>

        {<Form.Group className="mt-5">
          <Form.Label>Enable 1-Click Login</Form.Label>
          <Form.Text>Toggle this switch to enable/disable 1-Click Login for your application.. </Form.Text>
          <Form.Check
            disabled={!admin}
            custom
            type="switch"
            id="custom-switch"
            checked={settings.QUICK_LOGIN_ENABLED || settings.QUICK_LOGIN_ENABLED === undefined}
            label={`1-Click Connect ${settings.QUICK_LOGIN_ENABLED || settings.QUICK_LOGIN_ENABLED === undefined ? 'Enabled' : 'Disabled'}`}
            onChange={() => setSettings({ ...settings,
              QUICK_LOGIN_ENABLED: settings.QUICK_LOGIN_ENABLED === undefined ? false : !settings.QUICK_LOGIN_ENABLED
            })}
          />

        </Form.Group> }

        {/* <Form.Group className="mt-5">
          <Form.Label>Swoop Sidebar</Form.Label>
          <Form.Text>Toggle as to whether or not to show the Swoop sidebar to users. </Form.Text>
          <Form.Check
            disabled={!admin}
            custom
            type="switch"
            id="custom-switch-stepped-intro"
            checked={settings.SHOW_SWOOP_SIDEBAR || settings.SHOW_SWOOP_SIDEBAR === undefined}
            label={`Sidebar ${settings.SHOW_SWOOP_SIDEBAR || settings.SHOW_SWOOP_SIDEBAR === undefined ? 'Enabled' : 'Disabled'}`}
            onChange={() => setSettings({ ...settings,
              SHOW_SWOOP_SIDEBAR: settings.SHOW_SWOOP_SIDEBAR === undefined ? false : !settings.SHOW_SWOOP_SIDEBAR
            })}
          />
        </Form.Group> */}

        {/* <hr />
        <h2>Services</h2>

        <Form.Group className="mt-5" disabled={!admin}>
          <Form.Label>ReCAPTCHA</Form.Label>
          <Form.Text>Toggle as to whether or not to require reCAPTCHA to pass before authorization. </Form.Text>
          <Form.Check
            disabled={!admin}
            custom
            type="switch"
            id="custom-switch-captcha-enabled"
            checked={settings.CAPTCHA_ENABLED !== undefined && settings.CAPTCHA_ENABLED === true}
            label={`reCAPTCHA ${settings.CAPTCHA_ENABLED === true ? 'Enabled' : 'Disabled'}`}
            onChange={() => setSettings({ ...settings,
              CAPTCHA_ENABLED: settings.CAPTCHA_ENABLED === undefined ? true : !settings.CAPTCHA_ENABLED
            })}
          /><br />
            <Form.Label>SITE KEY</Form.Label>
            <Form.Text>
              Enter your ReCAPTCHA V3 SITE Key below. You can <a href="https://www.google.com/recaptcha/admin/create" target="_blank">sign up here</a>
            </Form.Text>
            <Form.Control
              disabled={!admin}
              data-testid="recaptcha-site-key"
              placeholder="Enter your ReCAPTCHA SITE Key"
              type="text"
              name="recaptcha-site-key"
              value={settings['SERVICES.RECAPTCHA.SITE_KEY']}
              onChange={e => {
                let value = e.target.value;
                setSettings({ ...settings,
                  'SERVICES.RECAPTCHA.SITE_KEY': value
                });
              }}
              />
              <br />
                <Form.Label>SECRET KEY</Form.Label>
                <Form.Text>
                  Enter your ReCAPTCHA V3 SECRET Key below.
                </Form.Text>
                <Form.Control
                  disabled={!admin}
                  data-testid="recaptcha-secret-key"
                  placeholder="Enter your ReCAPTCHA SECRET Key"
                  type="text"
                  name="recaptcha-secret-key"
                  value={settings['SERVICES.RECAPTCHA.SECRET_KEY']}
                  onChange={e => {
                    let value = e.target.value;
                    setSettings({ ...settings,
                      'SERVICES.RECAPTCHA.SECRET_KEY': value
                    });
                  }}
                  />
        </Form.Group> */}

        <Button data-testid="update-auth-flow-button" onClick={handleSubmit} disabled={!admin}>
          Save
        </Button>
      </Form>
    </>
  )
}

export default AuthFlow;
