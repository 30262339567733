import { call, put, takeLatest } from "redux-saga/effects";
import axios from 'axios';
import { errorHandler } from 'utils/sagas';

const ANALYTICS_API_BASE_URL = 'https://analytics.swoop.email/v1/authentications';

export function* getAuthenticationsMonthly(action) {
    const current = new Date();
    const end = new Date().getTime() / 1;
    const start = (new Date().setDate(current.getDate() - 30)) / 1;

    let url = `${ANALYTICS_API_BASE_URL}/count?properties[]=${action.payload.propertyId}&start=${start}&end=${end}`;    
    let response = yield call(axios.get, url);
    let authentications = response.data.authentications && response.data.authentications.length > 0 ? response.data.authentications[0].total : 0;
    yield put({ type: 'ANALYTICS_FULFILL_AUTHENTICATIONS_MONTHLY', payload: { authentications } });
}

export function* getActiveUsersMonthly(action) {
    const current = new Date();
    const end = new Date().getTime() / 1;
    const start = (new Date().setDate(current.getDate() - 30)) / 1;

    let url = `${ANALYTICS_API_BASE_URL}/users/count?properties[]=${action.payload.propertyId}&start=${start}&end=${end}`;
    let response = yield call(axios.get, url);    
    let users = response.data.authentications && response.data.authentications.length > 0 ? response.data.authentications[0].users : 0;
    yield put({ type: 'ANALYTICS_FULFILL_ACTIVE_USERS_MONTHLY', payload: { users } });
}

export function* getTotalUsers(action) {
    const current = new Date();
    const end = new Date().getTime() / 1;
    const start = new Date('0').getTime();

    let url = `${ANALYTICS_API_BASE_URL}/users/count?properties[]=${action.payload.propertyId}&start=${start}&end=${end}`;
    let response = yield call(axios.get, url);    
    let users = response.data.authentications[0].users;
    yield put({ type: 'ANALYTICS_FULFILL_TOTAL_USERS', payload: { users } });
}

export function* getAuthenticationsLocations(action) {
    const current = new Date();
    const end = new Date().getTime() / 1;
    const start = (new Date().setDate(current.getDate() - 30)) / 1;

    let url = `${ANALYTICS_API_BASE_URL}/coordinates?property=${action.payload.propertyId}&start=${start}&end=${end}`;
    let response = yield call(axios.get, url);
    let authentications = response.data.authentications;
    authentications = authentications.map(a => a.meta.geo);

    // Get country counts [flag name]
    let countries = authentications.map(a => `${a.location.country_flag_emoji} ${a.country_name}`);
    let countriesCount = countries.reduce((acc, cur) => {
        if (acc[cur]) {
            acc[cur] += 1;
        } else {
            acc[cur] = 1;
        }
        return acc;
    }, {});    

    // Get the flag out of the values
    countriesCount = Object.keys(countriesCount).map(c => {                
        let [flag, ...country] = c.split(' ');
        return {
            flag,
            country: country.join(' '),
            count: countriesCount[c]
        };
    });

    // Sort countries by count
    countriesCount = countriesCount.sort((a, b) => b.count - a.count);    

    yield put({ type: 'ANALYTICS_FULFILL_AUTHENTICATIONS_LOCATIONS', payload: { authentications, countries: countriesCount } });
}

export function* analyticsSaga() {
    yield takeLatest("ANALYTICS_GET_AUTHENTICATIONS_MONTHLY", errorHandler(getAuthenticationsMonthly, "UPDATE_LOAD_STATUS", "getAuthenticationsMonthlyStatus"));
    yield takeLatest("ANALYTICS_GET_ACTIVE_USERS_MONTHLY", errorHandler(getActiveUsersMonthly, "UPDATE_LOAD_STATUS", "getActiveUsersMonthlyStatus"));
    yield takeLatest("ANALYTICS_GET_TOTAL_USERS", errorHandler(getTotalUsers, "UPDATE_LOAD_STATUS", "getTotalUsersStatus"));
    yield takeLatest("ANALYTICS_GET_AUTHENTICATIONS_LOCATIONS", errorHandler(getAuthenticationsLocations, "UPDATE_LOAD_STATUS", "getAuthenticationsLocationsStatus"));
}
