import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialAuthState = {
  user: undefined,
  access_token: undefined,
  refresh_token: undefined,
  seenWelcome: false,
  last_refresh_time: null,
  refreshing: false,
  errors: []
};

const authReducer = persistReducer({ storage, key: "swoop-auth", whitelist: ["access_token", "refresh_token"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case "START_VERIFICATION": {
        return { ...state };
      }

      case "LOGOUT": {
        return initialAuthState;
      }

      case "REFRESH_AUTH_TOKENS": {
        return {
          ...state,
          refreshing: true
        }
      }

      case "REFRESHED_AUTH_TOKENS": {
        const { access_token, refresh_token } = action.payload;
        return {
          ...state,
          access_token,
          refresh_token,
          refreshing: false,
          last_refresh_time: new Date()
        }
      }

      case "COMPLETE_VERIFICATION": {
        const { user, access_token, refresh_token } = action.payload;
        let seenWelcome = state.seenWelcome || (user.meta && user.meta.SEEN_WELCOME ? user.meta.SEEN_WELCOME : false);
        return { ...state, user, access_token, refresh_token, seenWelcome, last_refresh_time: new Date() };
      }

      case "UPDATE_ADMIN_META": {
        let user = state.user;
        user.meta = { ...user.meta, ...action.payload };
        let seenWelcome = action.payload.SEEN_WELCOME;
        return { ...state, user, seenWelcome }
      }

      case "UPDATE_ADMIN_LOCALLY": {
        const updatedAdmin = action.payload;
        return { ...state, user: updatedAdmin }
      }

      case "REQUEST_FAIL": {
        const currentErrors = state.errors || [];
        const errors = [ ...currentErrors, action.payload ];
        return { ...state, errors }
      }

      default:
        return state;
    }
  }
);

export default authReducer;
