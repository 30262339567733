import { call, put } from "redux-saga/effects";

export const errorHandler = (saga, loadAction, actionKey) => function* (action) {
  try {
    yield put({ type: loadAction, key: actionKey, payload: "pending" })
    yield call(saga, action)
    yield put({ type: loadAction, key: actionKey, payload: "success" })
    yield put({ type: loadAction, key: actionKey, payload: null })
  } catch (err) {    
    let message = err.response && err.response.data && err.response.data.message ? err.response.data.message : err.message;
    yield put({ type: loadAction, key: actionKey, payload: "failed", message })
    yield put({ type: loadAction, key: actionKey, payload: null })
    console.log(err);
    console.log(message);
  }
}