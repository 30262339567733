import React, { useState, useEffect } from 'react';
import { Form, Button, Modal } from "react-bootstrap";
import Select from 'react-select';
import validator from 'email-validator';

const PermissionsModal = props => {
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [isRoleValid, setIsRoleValid] = useState("valid");
  const [permissionsValidated, setPermissionsValidated] = useState(false);

  useEffect(() => {
    setEmail(props.email || "");
  }, [props.email])

  useEffect(() => {
    setRole(props.role || "");
  }, [props.role])

  const getCrudText = () => {
    switch(props.crud){
      case "create":
        return "Add";
      case "update":
        return "Edit";
    }
  }

  const handleSubmit = e => {
    e.preventDefault();
    if(validator.validate(email) && role){
      props.onSubmit(e, email, parseInt(role.value, 10));
      props.onHide();
    } else {
      if(!role){
        setIsRoleValid("invalid");
      } else {
        setIsRoleValid("valid");
      }
      setPermissionsValidated(true);
    }
  }

  const handleSelect = selectedOption => {
    setRole(selectedOption);
    setIsRoleValid("valid");
  }

  const selectOptions = [
    { value: 0, label: 'Admin' },
    { value: 1, label: 'Member' },
  ];

  return (
    <Modal id={props.id} show={props.show} onHide={props.onHide} centered>
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body className="px-4 pb-4 pt-0">
        <h3 className="mb-3">{getCrudText()} User</h3>
        <Form noValidate validated={permissionsValidated}>
          <Form.Row>
            <Form.Group className="col-8 mb-4">
              <Form.Control
                id="permissions-email-input" 
                type="email" 
                value={email} 
                placeholder={props.email || "Enter invitee's email"} 
                onChange={e => setEmail(e.target.value)}
                required
                disabled={props.crud !== "create"}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid email address
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="col-4 mb-4" >
              <Select
                id="role-select"
                className={`react-select thin-select ${isRoleValid}`}
                classNamePrefix="react-select"
                value={role}
                onChange={handleSelect}
                isSearchable={false}
                placeholder={role || "Role"}
                required
                options={selectOptions}
              />
              <Form.Control.Feedback id="role-select-feedback" type="invalid">
                Please select a role
              </Form.Control.Feedback>  
            </Form.Group>
          </Form.Row>
          <Button data-testid="submit-permissions-button" className="w-50 w-md-30 float-right" onClick={handleSubmit}>
            {getCrudText()} User
          </Button>
        </Form>
      </Modal.Body>
    </ Modal>
  )
}

export default PermissionsModal