import React, { useEffect, useState } from "react";
import { Form, Button, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import {useSelector, useDispatch} from 'react-redux'
import MfaConfigForm from 'views/components/forms/MfaConfigForm';
import MfaReset from 'views/components/forms/MfaReset';
import MfaHelperText from 'views/components/MfaHelperText';
import SwoopToast from 'views/components/SwoopToast';
import { handleLoadToasts } from "utils/eventHandling";
import MixpanelClicks from 'mixpanel/MixpanelClicks';
import { trackedMfaElements as trackedElements } from 'mixpanel/trackedElements';

const MFA = (props) => {
  const dispatch = useDispatch();
  const property = useSelector(state => state.dashboard.selectedProperty || {});
  const propertyMfa = useSelector(state => state.dashboard.selectedProperty && state.dashboard.selectedProperty.mfa || {});
  const mfaPlugins = useSelector(state => state.dashboard.mfaPlugins || []);
  let admin = property.role === 0 ? true : false;
  // const users = useSelector(state => state.dashboard.selectedProperty.users || []);
  const loadStatus = useSelector(state => state.dashboard.loadStatuses && state.dashboard.loadStatuses.mfaLoadStatus);
  const [mfaEnabled, setMfaEnabled] = useState(false);
  const [mfaType, setMfaType] = useState({});
  const [mfaConfig, setMfaConfig] = useState([]);
  const [mfaSettings, setMfaSettings] = useState({});
  const [validated, setValidated] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState("valid");
  const [showUpdateSuccess, setShowUpdateSuccess] = useState(false);
  const [showUpdateFailure, setShowUpdateFailure] = useState(false);
  const [showResetSuccess, setShowResetSuccess] = useState(false);
  const [showResetFailure, setShowResetFailure] = useState(false);

  useEffect(() => {
    setMfaEnabled(propertyMfa.enabled);
  }, [propertyMfa.enabled])

  useEffect(() => {
    setMfaType(propertyMfa.pluginDetails || {});
  }, [propertyMfa.pluginDetails])

  useEffect(() => {
    console.log("mfaConfig", mfaConfig);
    setMfaConfig(propertyMfa.config || []);
  }, [propertyMfa.config])

  useEffect(() => {
    console.log("mfaSettings", propertyMfa);
    setMfaSettings(propertyMfa.settings || {});
  }, [propertyMfa.settings])

  useEffect(() => {
    handleLoadToasts(loadStatus, setShowUpdateSuccess, setShowUpdateFailure);
  }, [loadStatus]);

  useEffect(() => {
    dispatch({
      type: "FETCH_MFA_PLUGINS",
      organizationId: property.organization,
      property: property
    })
  }, []);

  useEffect(() => {
    if(mfaType.slug){
      console.log("mfaType", mfaType);
      dispatch({
        type: "FETCH_PLUGIN_CONFIG",
        slug: mfaType.slug
      })
    }
  }, [mfaType])


  // useEffect(() => {
  //   dispatch({
  //     type: "FETCH_PROPERTY_USERS",
  //     propertyId: property._id,
  //     organizationId: property.organization
  //   })
  // }, [])

  const handleSubmit = e => {
    e.preventDefault();
    const dispatchObj = {
      type: "UPDATE_PROPERTY_MFA",
      organizationId: property.organization,
      propertyId: property._id,
      params: { mfaEnabled, mfaType, mfaSettings }
    }

    if(mfaType && mfaType.name === "Twilio SMS"){
      if(mfaSettings.account_sid &&
        mfaSettings.account_sid.length > 0 &&
        mfaSettings.auth_token &&
        mfaSettings.auth_token.length > 0 &&
        mfaSettings.from_phone_number &&
        mfaSettings.from_phone_number.length > 1){
          dispatch(dispatchObj);
      } else {
        setIsPhoneValid("invalid");
        setValidated(true);
      }
    } else{
      dispatch(dispatchObj);
    }
  }

  return (
    <>
      {!props.testing && <MixpanelClicks elements={Object.values(trackedElements)} view="MFA"/>}
      <SwoopToast id="mfa-success-toast" success={true} setShow={setShowUpdateSuccess} show={showUpdateSuccess} message="MFA Updated" />
      <SwoopToast success={false} setShow={setShowUpdateFailure} show={showUpdateFailure} message="Failed to Update MFA" />
      <SwoopToast success={true} setShow={setShowResetSuccess} show={showResetSuccess} message="User's MFA Reset" />
      <SwoopToast success={false} setShow={setShowResetFailure} show={showResetFailure} message="Failed to Reset User's MFA" />
      <h2>Multi-factor Authentication</h2>
      <div className="heading-underline"></div>
      <Form noValidate validated={validated} className="mb-5 w-lg-60">
        <Form.Group>
          <Form.Label>MFA</Form.Label>
          <Form.Text className="subtext">
            Provide a second factor of authentication for your users. {admin === true ? 'true' : 'false'} foo
          </Form.Text>
          <Form.Check
            custom
            type="switch"
            id={trackedElements.mfaEnabled.id}
            checked={!!mfaEnabled}
            label={`MFA ${mfaEnabled ? 'Enabled' : 'Disabled'}`}
            onChange={() => setMfaEnabled(!mfaEnabled)}
            disabled={!admin}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>MFA Services</Form.Label>
          <Form.Text className="subtext">
            Your second factor of authentication can be easily set. Additional credentials may be required.
          </Form.Text>
          <ToggleButtonGroup
            type="radio"
            name="mfa-type-options"
            defaultValue={mfaType._id}
            value={mfaType._id}
            onChange={val => {              
              setMfaType(mfaPlugins.find(plugin => plugin._id === val))              
            }}
            disabled={!admin}
          >
            {mfaPlugins && mfaPlugins.map(plugin => (
              <ToggleButton
                id={`${plugin.slug}-button`}
                disabled={!mfaEnabled}
                key={plugin._id}
                value={plugin._id}
                className="mfa-button"
                disabled={!admin}
              >
                {plugin.name === "Twilio SMS" ? "SMS Text" : plugin.name}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Form.Group>
        {mfaEnabled &&
          <>
            {mfaConfig.map(config => (
              <>
              {config.type === "paragraph" &&
                <div id="mfa-helper-text-wrapper" className="mb-4">
                  <MfaHelperText config={config} />
                </div>
              }
              </>
            ))}            

            {mfaType.name === "Twilio SMS" &&
              <div id="mfa-config-form-wrapper">
                <MfaConfigForm
                  config={mfaConfig}
                  settings={mfaSettings}
                  setMfaSettings={setMfaSettings}
                  isPhoneValid={isPhoneValid}
                  setIsPhoneValid={setIsPhoneValid}
                />
              </div>
            }
          </>
        }
        <Button data-testid="update-mfa-button" type="submit" onClick={handleSubmit} disabled={!admin}>
          Update MFA Settings
        </Button>
      </Form>

      {mfaEnabled && admin &&
        <div id="mfa-reset-wrapper">
          <h2>Multi-Factor Authentication Reset</h2>
          <div className="heading-underline"></div>
          <MfaReset
            setShowSuccessToast={setShowResetSuccess}
            setShowFailureToast={setShowResetFailure}
            property={property}
          />
        </div>
      }
  </>
  )
}

export default MFA;
