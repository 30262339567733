import React, { useState, useEffect } from "react";
import { Form, ToggleButton, ToggleButtonGroup, Modal, Spinner, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import rechargeIcon from 'assets/images/recharge-icon.svg';
import { billingSaga } from "store/sagas/billingSagas";
import {
    useStripe,
} from "@stripe/react-stripe-js";

const RechargeModal = (props) => {
    const dispatch = useDispatch();
    const selectedOrganization = useSelector(state => state.organizations.selected);
    const intent = useSelector(state => state.billing.intent);
    const billing = useSelector(state => state.billing || {});
    const [loadState, setLoadState] = useState("DEFAULT");
    const stripe = useStripe();

    const products = [
        {
            id: 1,
            cost: 8.0,
            units: 1000,
            costPerUnit: 0.008,
        },
        {
            id: 2,
            cost: 16,
            units: 2000,
            costPerUnit: 0.008,
        },
        {
            id: 3,
            cost: 40,
            units: 5000,
            costPerUnit: 0.008,
        },
        {
            id: 4,
            cost: 80.00,
            units: 10000,
            costPerUnit: 0.008,
        },
    ]

    const [selectedProduct, setSelectedProduct] = useState(products[0]);

    const selectProduct = (product) => {
        setSelectedProduct(product);
    }

    const rechargeButtonClicked = (e) => {
        e.preventDefault();
        dispatch({ type: "BILLING_CREATE_PAYMENT_INTENT", payload: { amount: selectedProduct.cost * 100, organizationId: selectedOrganization._id } });
        setLoadState("LOADING");
    }

    useEffect(() => {
        if (props.show) {
            console.log("recharge modal show");

            if (intent) {

                stripe.confirmCardPayment(intent.client_secret, {
                    payment_method: billing.paymentMethod.id
                })
                    .then(payment => {
                        console.log(payment);
                        if (props.onConfirm) {
                            props.onConfirm();
                        }
                        setLoadState("DEFAULT");
                        dispatch({ type: "BILLING_PAYMENT_INTENT_FULFILL", payload: null });
                    })
                    .catch(error => {
                        console.log(error);
                        alert('Something went wrong');
                    });

            }
        }
    }, [intent]);


    return (
        <Modal centered show={props.show} onHide={props.onCancel}>
            <Modal.Header closeButton className="mt-2 justify-content-center">
                <img src={rechargeIcon} alt="recharge-icon" className="w-10 t-n110" />
            </Modal.Header>
            <Modal.Body className="pt-0">
                <div className="row justify-content-center text-center">
                    <div className="col-12 col-md-12">
                        <h3>Add Authentication Credits</h3>
                    </div>
                    <div className="col-12 col-md-12">
                        <p>
                            Recharge your account with authentication credits.
                        </p>
                    </div>
                </div>
                <Form noValidate>
                    <Form.Group>
                        <ToggleButtonGroup
                            type="radio"
                            name="products"
                            defaultValue={products[0].id}
                            className="recharge-button-group"
                            type="radio"
                            defaultValue={selectedProduct ? selectedProduct.id : null}
                            value={selectedProduct ? selectedProduct.id : null}
                            onChange={val => {
                                selectProduct(products.find(product => product.id === val))
                            }}
                            disabled={false}
                        >
                            {products.map(product => (
                                <ToggleButton
                                    type="radio"
                                    id={product.id}
                                    disabled={false}
                                    key={product.id}
                                    value={product.id}
                                    className="recharge-button"
                                >
                                    <><strong>{product.cost.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    })}</strong>&nbsp;{product.units.toLocaleString()} credits&nbsp;<span className="text-secondary">${product.costPerUnit}/credit</span></>
                                </ToggleButton>
                            ))}

                        </ToggleButtonGroup>
                    </Form.Group>
                    {/* <div className="row justify-content-center text-center">
                        <a href="#" className="btn btn-primary" onClick={rechargeButtonClicked}>Confirm Recharge</a>
                    </div> */}

                    <Button className="btn btn-primary"
                        data-testid="confirm-recharge"
                        onClick={rechargeButtonClicked}
                        disabled={loadState === "LOADING"}>
                        {loadState === "LOADING" ? <><Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        /> Recharging...</> : "Confirm Recharge"}
                    </Button>


                    <div>&nbsp;</div>
                    <div className="row justify-content-center text-center">
                        <a disabled={loadState === "LOADING"} href="#" className="text-secondary" onClick={(e) => { e.preventDefault(); props.onCancel(); }}>Cancel</a>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>)
}
export default RechargeModal;
