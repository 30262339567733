import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { errorHandler } from 'utils/sagas';

const DASHBOARD_API_BASE_URL = process.env.REACT_APP_DASHBOARD_API_ENDPOINT;
const DASHBOARD_API_ENDPOINT = `${DASHBOARD_API_BASE_URL}/api`;

// worker sagas
export function* fetchPropertyAdmins(action){
  let url = `${DASHBOARD_API_ENDPOINT}/organizations/${action.organizationId}/properties/${action.propertyId}/admins`;
  try {
    const { data: propertyAdmins } = yield call(axios.get, url);
    yield put({ type: "FULFILL_PROPERTY_ADMINS", payload: propertyAdmins })
  } catch (error) {
    console.log(error);
  }
}

export function* createPropertyAdmin(action){
  let url = `${DASHBOARD_API_ENDPOINT}/organizations/${action.organizationId}/properties/${action.propertyId}/admins`;
  const { data: propertyAdmin } = yield call(axios.post, url, action.params);
  yield put({ type: "ADD_PROPERTY_ADMIN", payload: propertyAdmin });
}

export function* updatePropertyAdmin(action){
  let url = `${DASHBOARD_API_ENDPOINT}/organizations/${action.organizationId}/properties/${action.propertyId}/admins/${action.adminId}`;
  const { data: respObj } = yield call(axios.put, url, action.params);
  yield put({ type: "FULFILL_UPDATED_PROPERTY_ADMIN", payload: respObj });
}

export function* deletePropertyAdmin(action){
  let url = `${DASHBOARD_API_ENDPOINT}/organizations/${action.organizationId}/properties/${action.propertyId}/admins/${action.adminId}`;
  const { data: respObj } = yield call(axios.delete, url);
  yield put({ type: "FULFILL_DELETED_PROPERTY_ADMIN", payload: respObj.adminId })
}

// saga aggregator
export function* propertyAdminSaga() {
  yield takeLatest("FETCH_PROPERTY_ADMINS", fetchPropertyAdmins);
  yield takeLatest(
    "CREATE_PROPERTY_ADMIN", 
    errorHandler(createPropertyAdmin, "UPDATE_LOAD_STATUS", "permissionsGrantedStatus")
  );
  yield takeLatest(
    "UPDATE_PROPERTY_ADMIN", 
    errorHandler(updatePropertyAdmin, "UPDATE_LOAD_STATUS", "permissionsUpdatedStatus")
  );
  yield takeLatest(
    "DELETE_PROPERTY_ADMIN", 
    errorHandler(deletePropertyAdmin, "UPDATE_LOAD_STATUS", "permissionsDeletedStatus")
  );
}
