import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
    authentications: {},
    users: {},
};

const analyticsReducer = persistReducer({ storage, key: "swoop-analytics", whitelist: [] },
    (state = initialState, action) => {
        switch (action.type) {
            case "LOGOUT": {
                return initialState;
            }
            case "PROPERTIES_SELECT": {
                return initialState;
            }                       
            case "ANALYTICS_FULFILL_AUTHENTICATIONS_MONTHLY": {
                return {
                    ...state,
                    authentications: {
                        ...state.authentications,
                        "monthly": action.payload.authentications,
                    }
                };
            }
            case "ANALYTICS_FULFILL_ACTIVE_USERS_MONTHLY": {
                return {
                    ...state,
                    users: {
                        ...state.users,
                        "monthly": action.payload.users,
                    }
                };
            }
            case "ANALYTICS_FULFILL_TOTAL_USERS": {
                return {
                    ...state,
                    users: {
                        ...state.users,
                        "total": action.payload.users,
                    }
                };
            }

            case "ANALYTICS_FULFILL_AUTHENTICATIONS_LOCATIONS": {
                return {
                    ...state,
                    authentications: {
                        ...state.authentications,
                        "locations": action.payload.authentications,
                        "countries": action.payload.countries,
                    }
                };
            }
            default:
                return state;
        }
    }
);

export default analyticsReducer;
