import React from 'react';

const MfaHelperText = props => {  
  return (<div dangerouslySetInnerHTML={{ __html: props.config.label }} />);
  // switch(props.mfaType.name){
  //   case "Pin Pad":
  //     return <p>Upon first visit your users will be asked to set a personal 4 digit PIN. Subsequent visits they will enter this PIN as a second factor of authentication.</p>
  //   case "Twilio SMS":
  //     return (
  //       <>
  //         <p>Upon first visit your users will be asked for their phone number. They will be texted a code via SMS Text to enter for authentication. Subsequent visits they will be texted a new code as a second factor of authentication.</p>
  //         <br/>
  //         <p>To complete set up of this second factor of authentication, sign up for a <a href="https://www.twilio.com/">Twilio Account</a> and paste your credentials be</p>
  //       </>
  //     )
  //   default:
  //     return ""
  // }
}

export default MfaHelperText;