import React from 'react';
import { useRouteMatch, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Dropdown, ListGroup } from "react-bootstrap";
import { isActive } from 'utils/styleGenerators';
import throne from 'assets/images/settings-icon.svg';
import throneFilled from 'assets/images/settings-icon-filled.svg';
import castle from 'assets/images/credentials-icon.svg';
import castleFilled from 'assets/images/credentials-icon-filled.svg';
import horn from 'assets/images/branding-icon.svg';
import hornFilled from 'assets/images/branding-icon-filled.svg';
import swords from 'assets/images/auth-flow-icon.svg';
import swordsFilled from 'assets/images/auth-flow-icon-filled.svg';
import padlock from 'assets/images/mfa-icon.svg';
import padlockFilled from 'assets/images/mfa-icon-filled.svg';
import key from 'assets/images/permissions-icon.svg';
import keyFilled from 'assets/images/permissions-icon-filled.svg';
import downCaret from 'assets/images/caret-down-green.svg';
import balance from 'assets/images/balance-icon.svg';
import balanceFilled from 'assets/images/balance-icon-filled.svg';

const PropertySubNav = (props) => {
  const match = useRouteMatch();
  const location = useLocation();
  const settingsPath = `${match.path}/settings`;
  const credentialsPath = `${match.path}/credentials`;
  const brandingPath = `${match.path}/branding`;
  const authFlowPath = `${match.path}/options`;
  const mfaPath = `${match.path}/mfa`;
  const analyticsPath = `${match.path}/analytics`;
  const permissionsPath = `${match.path}/permissions`;
  
  const selectedProperty = useSelector(state => state.dashboard.selectedProperty);
  let admin = selectedProperty.role === 0 ? true : false;

  if (props.desktop){
    return (
      <ListGroup className="subnav">
        <ListGroup.Item action href={`/#${analyticsPath}`} className={isActive(location, [analyticsPath, match.path])}>
          <img src={balanceFilled} alt="balance-icon" className="subnav-icon default"/>
          <img src={balance} alt="balance-icon" className="subnav-icon active"/>
          Analytics
        </ListGroup.Item>
        <ListGroup.Item action href={`/#${settingsPath}`} className={isActive(location, [settingsPath])} >
          <img src={throne} alt="throne-icon" className="subnav-icon default"/>
          <img src={throneFilled} alt="throne-icon" className="subnav-icon active"/>
          Settings
        </ListGroup.Item>
        <ListGroup.Item action href={`/#${credentialsPath}`} className={isActive(location, [credentialsPath])}>
          <img src={castle} alt="castle-icon" className="subnav-icon default"/>
          <img src={castleFilled} alt="castle-icon" className="subnav-icon active"/>
          Credentials
        </ListGroup.Item>
        {/* <ListGroup.Item action href={`/#${brandingPath}`} className={isActive(location, [brandingPath])}>
          <img src={horn} alt="horn-icon" className="subnav-icon default"/>
          <img src={hornFilled} alt="horn-icon" className="subnav-icon active"/>
          Branding
        </ListGroup.Item> */}
        <ListGroup.Item action href={`/#${authFlowPath}`} className={isActive(location, [authFlowPath])}>
          <img src={swords} alt="swords-icon" className="subnav-icon default"/>
          <img src={swordsFilled} alt="swords-icon" className="subnav-icon active"/>
          Options
        </ListGroup.Item>
        <ListGroup.Item action href={`/#${mfaPath}`} className={isActive(location, [mfaPath])}>
          <img src={padlock} alt="padlock-icon" className="subnav-icon default"/>
          <img src={padlockFilled} alt="padlock-icon" className="subnav-icon active"/>
          MFA
        </ListGroup.Item>        
        {admin && <>
        <ListGroup.Item action href={`/#${permissionsPath}`} className={isActive(location, [permissionsPath])}>
          <img src={key} alt="key-icon" className="subnav-icon default"/>
          <img src={keyFilled} alt="key-icon" className="subnav-icon active"/>
          Access
        </ListGroup.Item>
        </>}
      </ListGroup>
    )
  } else if (props.mobile) {
    return (
      <Dropdown id="mobile-subnav-dropdown" className="subnav">
        <Dropdown.Toggle variant="success" className="not-button text-left w-100">
          {props.property.name}
          <img src={downCaret} alt="down caret" className="ml-2" />
        </Dropdown.Toggle>
        <Dropdown.Menu className="mobile-nav-dropdown-menu">
          <Dropdown.Item href={`/#${settingsPath}`}>
            <img src={throne} alt="throne-icon" className="subnav-icon"/>
            Settings
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item href={`/#${credentialsPath}`}>
            <img src={castle} alt="castle-icon" className="subnav-icon"/>
            Credentials
          </Dropdown.Item>
          <Dropdown.Divider />
          {/* <Dropdown.Item href={`/#${brandingPath}`}>
            <img src={horn} alt="horn-icon" className="subnav-icon"/>
            Branding
          </Dropdown.Item>
          <Dropdown.Divider /> */}
          <Dropdown.Item href={`/#${authFlowPath}`}>
            <img src={swords} alt="swords-icon" className="subnav-icon"/>
            Options
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item href={`/#${mfaPath}`}>
            <img src={padlock} alt="padlock-icon" className="subnav-icon"/>
            MFA
          </Dropdown.Item>
          {admin && <>
          <Dropdown.Divider />
          <Dropdown.Item href={`/#${permissionsPath}`}>
            <img src={key} alt="key-icon" className="subnav-icon"/>
            Access
          </Dropdown.Item>
          </>}
        </Dropdown.Menu>
      </Dropdown>
    )
  }
}

export default PropertySubNav;
