import React, { useState, useEffect } from "react";
import { Form, Button, Modal, InputGroup } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import SwoopToast from 'views/components/SwoopToast';
import skull from 'assets/images/skull-icon.svg';
import { handleLoadToasts } from "utils/eventHandling";
import MixpanelClicks from 'mixpanel/MixpanelClicks';
import {isOrganizationAdmin} from 'utils/permissions';

const Settings = props => {
  const organization = useSelector(state => state.organizations.selected);
  const user = useSelector(state => state.auth.user);
  const loadStatus = useSelector(state => state.dashboard.loadStatuses && state.dashboard.loadStatuses.organizationLoadStatus);
  const admin = isOrganizationAdmin(user, organization);
  const dispatch = useDispatch();
  const history = useHistory();
  const [name, setName] = useState(organization.name);
  const [updateValidated, setUpdateValidated] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [delete_name, setDeleteName] = useState(null);

  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showFailureToast, setShowFailureToast] = useState(false);

  useEffect(() => {
    handleLoadToasts(loadStatus, setShowSuccessToast, setShowFailureToast);
  }, [loadStatus])

  const handleFormSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    
    if(name.length > 0 ) {
      dispatch({ 
        type: "UPDATE_ORGANIZATION",
        organizationId: organization._id,        
        params: { name } 
      });
    } else {
      setUpdateValidated(true);
    }
  }

  const handleDeleteSubmit = e => {
    e.preventDefault();
    if(delete_name === name) {
      dispatch({
        type: "DELETE_ORGANIZATION",
        organizationId: organization._id,        
        history
      })
    }
  }
  
  return (
    <> 
      {!props.testing && <MixpanelClicks elements={[]} view="Settings"/>} 
      <SwoopToast id="settings-success-toast" success={true} setShow={setShowSuccessToast} show={showSuccessToast} message="Settings Updated" />
      <SwoopToast success={false} setShow={setShowFailureToast} show={showFailureToast} message={"Failed to Update Settings"} />
      <h2>Settings</h2>
      <div className="heading-underline"></div>
      <div>
        <p className="text-secondary"><strong>Team ID:</strong> {organization._id}</p><br />
      </div>
      <Form noValidate validated={updateValidated} className="w-lg-60" onSubmit={handleFormSubmit}>
        <Form.Group>
          <Form.Label>Team Name</Form.Label>
          <Form.Text className="subtext">
            The name of your team
          </Form.Text>
          <Form.Control
            data-testid="organization-name"
            placeholder="Enter your team name"
            type="text"
            name="name"
            value={name}
            onChange={e => setName(e.target.value)}
            required          
            disabled = {!admin}  
            />
          <Form.Control.Feedback id="invalid-app-name" type="invalid">
            Please give your team a name
          </Form.Control.Feedback>
        </Form.Group>

        <Button data-testid="save-settings" type='submit' disabled={!admin} onClick={handleFormSubmit}>
          Update Settings
        </Button>
      </Form>

      {admin &&
      <>
      <div className="my-5">
        <p className="warning-text">DELETION DUNGEON</p>
        <p className="subtext my-2">
          Permanently delete your team? Deleting your team is irreversible.
        </p>
        <Button 
          className="warning-button" 
          onClick={() => setShowDeleteModal(true)} 
          disabled={!admin}
          data-testid="delete-app-modal-button"
        >
          Delete App
        </Button>
      </div>
      
      <Modal show={showDeleteModal} centered onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton className="mt-2 justify-content-center">
          <img src={skull} alt="skull-icon" className="w-10 t-n110"/>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <Form noValidate onSubmit={handleDeleteSubmit}>
            <Form.Group>
              <h2>Delete Organization</h2>
              <Form.Text className="subtext">
                Deleting your team is irreversible. Enter the name of the team to confirm you want to permanently delete it:
              </Form.Text>
              <Form.Control
                data-testid="delete-name"
                placeholder={`${organization.name}`}
                name="delete_name"
                type="text"
                onChange={e => setDeleteName(e.target.value)}
                isInvalid={delete_name !== name}
              />
              <Form.Control.Feedback type="invalid">
                The name entered must match your team's name
              </Form.Control.Feedback>
            </Form.Group>
            <Button data-testid="delete-organization" onClick={handleDeleteSubmit} disabled={!admin || delete_name !== name}>
              Delete
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      </>}
      
    </>
  )
}

export default Settings;
