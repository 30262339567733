import React from 'react';
import { FormControl, InputGroup, Dropdown } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const Search = props => {
  const dispatch = useDispatch();
  const history = useHistory();

  const filterResourcesChanged = (e) => {
    let search = e.target.value;
    props.setSearchValue(search);
    if (search.length > 0) {
      props.setFilteredResources(props.resources.filter(resource => resource[props.searchKey].toLowerCase().includes(search.toLowerCase())) );
    } else {
      props.setFilteredResources(props.resources);
    }
  }

  return (
    <Dropdown>
      <Dropdown.Toggle variant="success" id="search-dropdown" className={`not-button ${props.className}`}>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text className={`${props.valid ? 'valid' : 'invalid'}`}>
              <i className="fa fa-search"/>
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            data-testid={props.inputId} 
            placeholder={props.placeholder} 
            value={props.searchValue}
            onChange={filterResourcesChanged}
            className={`${props.valid ? 'valid' : 'invalid'}`}
          />
        </InputGroup>
      </Dropdown.Toggle>
      <Dropdown.Menu className="search-dropdown">
        {props.filteredResources.slice(0, props.resultsLimit || 5).map((resource, idx) => (
          <Dropdown.Item 
            key={idx} 
            className={idx === 0 ? 'top-item' : ''}
            onClick={e => props.onResultClick(e, resource, dispatch, history)}
          >
            {resource[props.searchKey]}
          </Dropdown.Item>
        ))}
        {props.lastItemText &&
          <>
            <Dropdown.Divider className="last-dropdown-divider" />
            <span onClick={props.onLastItemClick} className="bold-green bottom-item dropdown-item fake-button">
              {props.lastItemText}
            </span>
          </>
        }
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default Search;