import { call, put, takeLatest, select } from "redux-saga/effects";
import axios from "axios";
import { errorHandler } from 'utils/sagas';

const DASHBOARD_API_BASE_URL = process.env.REACT_APP_DASHBOARD_API_ENDPOINT;
const DASHBOARD_API_ENDPOINT = `${DASHBOARD_API_BASE_URL}/api`;

export const personal = (userId, organizations) => {
  let personal = organizations.filter(org => {
    let mine = org.organizationAdmins.find(oa => oa.admin === userId && oa.role === 0);
    return org.personal && mine;
  });
  if (personal.length > 0) {
    return personal[0];
  }
  return null;
}

export function* createOrganization(action) {
  let url = `${DASHBOARD_API_ENDPOINT}/organizations`;
  const { data: organization } = yield call(axios.post, url, action.params);
  yield put({ type: "FETCH_ORGANIZATIONS", payload: {} });
  yield put({ type: "FETCH_ORGANIZATION_ADMINS", organizationId: organization._id });
  yield put({ type: "ORGANIZATION_SELECT", payload: { ...organization } });
  yield put({ type: "PROPERTIES_SELECT", payload: null });    
  yield call(action.history.push, '/admin/dashboard');
}

function* fetchOrganizations() {
  let user = yield select(state => state.auth.user);
  let url = `${DASHBOARD_API_ENDPOINT}/organizations`;
  const response = yield call(axios.get, url);
  if (response && response.data) {
    let organizations = response.data;
    const personalOrg = personal(user._id, organizations);
    yield put({ type: "ORGANIZATIONS_SHOW", payload: { organizations, personal: personalOrg } });
    let selectedOrganization = yield select(state => state.organizations.selected);
    if (!selectedOrganization || !selectedOrganization._id) {
      yield put({ type: "ORGANIZATION_SELECT", payload: personalOrg });
    } else {
      let updatedSelectedOrganization = organizations.find(org => org._id === selectedOrganization._id);
      yield put({ type: "ORGANIZATION_SELECT", payload: updatedSelectedOrganization });
    }
  }
}

export function* updateOrganization(action) {
  let url = `${DASHBOARD_API_ENDPOINT}/organizations/${action.organizationId}`;
  const { data: updatedOrganization } = yield call(axios.put, url, action.params);

  // the attribute role: 0 is added so an additional call to the api is not needed to check if
  // the user has admin privileges for this property. Temp fix until API refactor.
  yield put({ type: "ORGANIZATION_SELECT", payload: { ...updatedOrganization, role: 0 } });
  yield put({ type: "FETCH_ORGANIZATIONS", payload: {} });
}

export function* deleteOrganization(action) {
  try {
    let url = `${DASHBOARD_API_ENDPOINT}/organizations/${action.organizationId}`;
    yield call(axios.delete, url);
    yield put({ type: "FETCH_ORGANIZATIONS", payload: {} });
    let personal = yield select(state => state.organizations.personal);
    yield put({ type: "ORGANIZATION_SELECT", payload: { ...personal } });
    yield call(action.history.push, '/admin/dashboard');
  } catch (error) {
    console.log(error);
  }
}

export function* organizationSelected(action) {
  // yield put({ type: "BILLING_FULFILL", payload: null });
  yield put({ type: "FETCH_ORGANIZATION_ADMINS", organizationId: action.payload._id });
}

export function* selectPersonalOrganization(action) {
  console.log(action)
  if (action.payload.leave) {    
    let personal = yield select(state => state.organizations.personal);
    if(personal) {
      yield put({ type: "ORGANIZATION_SELECT", payload: { ...personal } });
    } else {
      console.log('no personal org');
      console.log(personal)
      alert(personal)
    }
    yield call(action.history.push, '/admin/dashboard');
  }
}

export function* organizationSaga() {
  yield takeLatest("CREATE_ORGANIZATION", errorHandler(createOrganization, "UPDATE_LOAD_STATUS", "organizationCreateStatus"));
  yield takeLatest("DELETE_ORGANIZATION", errorHandler(deleteOrganization, "UPDATE_LOAD_STATUS", "organizationDeleteStatus"));
  yield takeLatest("ORGANIZATION_SELECT", organizationSelected);
  yield takeLatest("FETCH_ORGANIZATIONS", errorHandler(fetchOrganizations, "UPDATE_LOAD_STATUS"));
  yield takeLatest("UPDATE_ORGANIZATION", errorHandler(updateOrganization, "UPDATE_LOAD_STATUS", 'organizationLoadStatus'));
  // yield takeLatest("DELETE_ORGANIZATION_ADMIN", selectPersonalOrganization);
  yield takeLatest("SELECT_PERSONAL_ORGANIZATION", selectPersonalOrganization);
}
