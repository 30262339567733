import React, { useState } from 'react'
import { Button, Form } from "react-bootstrap";
import PhoneInput from 'react-phone-input-2';
import Select from 'react-select';

const UserProfileForm = props => {
  const [firstName, setFirstName] = useState(props.firstName || "");
  const [lastName, setLastName] = useState(props.lastName || "");
  const [phoneNumber, setPhoneNumber] = useState(props.phoneNumber || "");
  const [companySize, setCompanySize] = useState(props.companySize || "");
  const [validated, setValidated] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (firstName.length > 0){
      props.onSubmit(firstName, lastName, phoneNumber, companySize.value)
    } else {
      setValidated(true);
    }
  }

  const displayButtonText = () => {
    switch(props.crud){
      case "update":
        return "Update Profile";
      case "welcome":
        return "Submit Profile Info";
    }
  }

  const selectOptions = [
    { value: "Just me", label: "Just me" },
    { value: "2 to 10", label: '2 to 10' },
    { value: "More than 10", label: "More than 10"}
  ]

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12 col-md-6">
          <Form.Group controlId="formFirstName">
            <Form.Label>First Name *</Form.Label>
            <Form.Control
              type="text"
              value={firstName}
              placeholder="Enter your name"
              onChange={e => setFirstName(e.target.value)}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please fill in your first name.
            </Form.Control.Feedback>
          </Form.Group>
        </div>
        <div className="col-12 col-md-6">
          <Form.Group controlId="formLastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              value={lastName}
              placeholder="Enter your name"
              onChange={e => setLastName(e.target.value)}
            />
          </Form.Group>
        </div>
      </div>
      <Form.Group className={props.crud === "update" ? 'd-block' : 'd-none'}>
        <Form.Label>Email</Form.Label>
        <Form.Text className="subtext">
          Contact 1-Click Login Support to update your email.
        </Form.Text>
        <Form.Control
          type="email"
          value={props.email}
          disabled
        />
      </Form.Group>
      <Form.Group controlId="formPhoneNumber">
        <Form.Label>Your Phone Number</Form.Label>
        <Form.Text className="subtext">
          In case there's a problem with your account, or if you'd like to give us your feedback on 1-Click Login.
        </Form.Text>
        <PhoneInput
          country={'us'}
          value={phoneNumber}
          onChange={setPhoneNumber}
          enableSearch={true}
        />
      </Form.Group>
      <Form.Group controlId="formPhoneNumber">
        <Form.Label>How Many People Work At Your Company?</Form.Label>
        <Select
          className="react-select"
          classNamePrefix="react-select"
          value={companySize}
          onChange={selectedOption => setCompanySize(selectedOption)}
          isSearchable={false}
          placeholder={companySize || ""}
          options={selectOptions}
        />
      </Form.Group>
      <Button type="submit">
        {displayButtonText()}
      </Button>
    </Form>
  )
}

export default UserProfileForm
