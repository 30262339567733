import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialDashboardState = {
  properties: [],
  mfaPlugins: [],
  selectedProperty: null,
  errors: [],
  loadStatuses: {}
};

// TO DO: split up reducer by resource
const dashboardReducer = persistReducer( { storage, key: "swoop-dashboard", whitelist: [ "properties", "selectedProperty"] },
  ( state = initialDashboardState, action ) => {
    switch ( action.type ) {
      case "PROPERTIES_SHOW": {
        const { properties } = action.payload;
        return { ...state, properties };
      }

      case "PROPERTIES_SELECT": {
        const selectedProperty  = action.payload;
        return { ...state, selectedProperty };
      }

      case "FULFILL_PROPERTY_ADMINS": {
        const updatedProperty = { ...state.selectedProperty, ...action.payload }
        return { ...state, selectedProperty: updatedProperty }
      }

      case "ADD_PROPERTY_ADMIN": {
        const updatedPropertyAdmins = [...state.selectedProperty.propertyAdmins, { ...action.payload.propertyAdmin }];
        const updatedProperty = { ...state.selectedProperty, propertyAdmins: updatedPropertyAdmins };
        return { ...state, selectedProperty: updatedProperty }
      }

      case "FULFILL_UPDATED_PROPERTY_ADMIN": {
        const updatedPropertyAdmins = state.selectedProperty.propertyAdmins.map(admin => {
          if(admin._id != action.payload.adminId){
            return admin
          } else {
            return { ...admin, role: action.payload.role }
          }
        });
        const updatedProperty = { ...state.selectedProperty, propertyAdmins: updatedPropertyAdmins };
        return { ...state, selectedProperty: updatedProperty }
      }

      case "FULFILL_DELETED_PROPERTY_ADMIN": {
        const updatedPropertyAdmins = state.selectedProperty.propertyAdmins.filter(admin => admin._id != action.payload);
        const updatedProperty = { ...state.selectedProperty, propertyAdmins: updatedPropertyAdmins };
        return { ...state, selectedProperty: updatedProperty }
      }

      case "PROPERTIES_REQUEST_FAILED": {
        const currentErrors = state.errors || [];
        const errors = [ ...currentErrors, action.payload ];
        return { ...state, errors }
      }

      case "FULFILL_MFA_PLUGINS_LOCALLY": {
        return { ...state, mfaPlugins: action.payload };
      }

      case "FULFILL_UPDATED_MFA": {
        const updatedPropertyMfa = { ...state.selectedProperty.mfa, ...action.payload }
        const updatedProperty = { ...state.selectedProperty, mfa: updatedPropertyMfa }
        return { ...state, selectedProperty: updatedProperty }
      }

      case "FULFILL_DISABLE_MFA": {
        const updatedSelectedProperty = { ...state.selectedProperty, 
          mfa: {
            enabled: false,
            settings: {},
            mfa_plugin: null
          } 
        };
        return { ...state, selectedProperty: updatedSelectedProperty }
      }

      case 'FULFILL_PROPERTY_USERS': {
        const updatedSelectedProperty = { ...state.selectedProperty, users: action.payload }
        return { ...state, selectedProperty: updatedSelectedProperty }
      }

      case "UPDATE_LOAD_STATUS": {
        const updatedLoadStatuses = { ...state.loadStatuses, [action.key]: action.payload}
        return { ...state, loadStatuses: updatedLoadStatuses}
      }
      
      case "LOGOUT": {
        return initialDashboardState;
      }

      default:
        return state;
    }
  }
);

export default dashboardReducer