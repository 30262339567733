import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import addIcon from 'assets/images/add-icon.svg';

const NewProperty = () => {
  const [show, setShow] = useState(true);
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState(""); 
  const [url, setUrl] = useState("");
  const selectedOrganization = useSelector(state => state.organizations.selected);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleFormSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setValidated(true);

    if(name.length > 0 & url.length > 0) {
      dispatch({ 
        type: "CREATE_PROPERTY",  
        organizationId: selectedOrganization._id, 
        params: { name, url, mfa: {} },
        history
      });
    }
  }

  const handleHide = () => {
    setShow(false);
    history.goBack();
  }

  return (
    <Modal show={show} onHide={handleHide}>
      <Modal.Header closeButton className="justify-content-center">
        <img src={addIcon} alt="add-icon" className="w-10 t-n20 d-none d-md-block"/>
        <div className="row justify-content-center text-center w-100 mt-2 mt-md-5">
          <div className="col-12 col-md-9">
            <h3>Add a New App</h3>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
          <Form.Group>
            <Form.Label>App Name *</Form.Label>
            <Form.Text className="subtext">
              The name displayed to your users during authorization. You can change this later.
            </Form.Text>
            <Form.Control
              name="name"
              data-testid = "property-name"
              value={name}
              placeholder="Enter your app/website name"
              type="text"
              required
              onChange={e => setName(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Please give your app a name
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>App URL *</Form.Label>
            <Form.Text className="subtext">
              Home page of your app. You can change this later.
            </Form.Text>
            <Form.Control
              data-testid="property-url"
              name="url"
              value={url}
              placeholder="https://www.example.com"
              type="text"
              required
              onChange={e => setUrl(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Please fill in your app's url
            </Form.Control.Feedback>
          </Form.Group>
          <Button data-testid="submit-form" type='submit'>
            Add It!
          </Button>
        </Form>
        <p onClick={handleHide} className="subtext fake-link text-center my-3">
          I'd like to look around first
        </p>
      </Modal.Body>
    </Modal>
  )
}

export default NewProperty

