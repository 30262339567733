import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import UserProfileForm from 'views/components/forms/UserProfileForm';
import knight from 'assets/images/knight-modal.svg';

const WelcomeModal = () => {
  const [show, setShow ] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = (firstName, lastName, phoneNumber, companySize) => {
    dispatch({ type: "UPDATE_ADMIN", 
      params: { firstName, lastName, phoneNumber, companySize }
    })
    dispatch({ type: "COMPLETE_REGISTRATION" });
    setShow(false);
    history.push('/admin/new-property');
  }

  return (
    <Modal id="welcome-modal" show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton className="pt-2rem">
        <img src={knight} alt="knight-icon" className="d-none d-md-block modal-avatar" />
        <div className="row justify-content-center text-center">
          <div className="col-12 col-md-9">
            <h3>Welcome to 1-Click Login!</h3>
          </div>
          <div className="col-12 col-md-9">
            <p>
              We'd love to get to know you a little and we'll never share your private information. 
            </p>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="pb-md-4 pt-md-2">
        <UserProfileForm onSubmit={onSubmit} crud="welcome" />
        <p onClick={() => setShow(false)} className="subtext fake-link text-center my-3">
          I'd like to look around first
        </p>
      </Modal.Body>
    </Modal>);
  }
export default WelcomeModal;
