import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { Form, Button } from "react-bootstrap";
import PermissionsModal from '../../components/modals/PermissionsModal';
import SwoopToast from 'views/components/SwoopToast';
import { handleLoadToasts } from "utils/eventHandling";
import pen from 'assets/images/edit-icon.svg';
import trashCan from 'assets/images/delete-icon.svg'
import { isOrganizationAdmin } from 'utils/permissions';
import ConfirmModal from 'views/components/modals/ConfirmModal';

const Permissions = () => {

    useEffect(() => {
        dispatch({
            type: "FETCH_ORGANIZATION_ADMINS",
            organizationId: selectedOrganization._id
        })
    }, [])

    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector(state => state.auth.user);
    const selectedOrganization = useSelector(state => state.organizations.selected);
    const organizationAdmins = useSelector(state => state.organizations.admins) || [];
    let admin = user;//isOrganizationAdmin(user, selectedOrganization);
    // let lastAdmin = organizationAdmins.length === 1;

    const permissionsGrantedStatus = useSelector(state => state.dashboard.loadStatuses && state.dashboard.loadStatuses.permissionsGrantedStatus);
    const permissionsUpdatedStatus = useSelector(state => state.dashboard.loadStatuses && state.dashboard.loadStatuses.permissionsUpdatedStatus);
    const permissionsDeletedStatus = useSelector(state => state.dashboard.loadStatuses && state.dashboard.loadStatuses.permissionsDeletedStatus);
    const [showInviteSuccess, setShowInviteSuccess] = useState(false);
    const [showInviteFailure, setShowInviteFailure] = useState(false);
    const [showUpdateSuccess, setShowUpdateSuccess] = useState(false);
    const [showUpdateFailure, setShowUpdateFailure] = useState(false);
    const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);
    const [showDeleteFailure, setShowDeleteFailure] = useState(false);

    const [showInviteModal, setShowInviteModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedAdmin, setSelectedAdmin] = useState(null);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    useEffect(() => {
        handleLoadToasts(permissionsGrantedStatus, setShowInviteSuccess, setShowInviteFailure);
    }, [permissionsGrantedStatus])

    useEffect(() => {
        handleLoadToasts(permissionsUpdatedStatus, setShowUpdateSuccess, setShowUpdateFailure);
    }, [permissionsUpdatedStatus]);

    useEffect(() => {
        handleLoadToasts(permissionsDeletedStatus, setShowDeleteSuccess, setShowDeleteFailure);
    }, [permissionsDeletedStatus])

    const createOrganizationAdmin = (e, email, role) => {
        e.preventDefault();
        dispatch({
            type: "CREATE_ORGANIZATION_ADMIN",
            organizationId: selectedOrganization._id,
            params: { email, role }
        })
    }

    const updateOrganizationAdmin = (e, email, role) => {
        e.preventDefault();
        dispatch({
            type: "UPDATE_ORGANIZATION_ADMIN",
            organizationId: selectedOrganization._id,
            adminId: selectedAdmin._id,
            params: { email, role }
        })
    }

    const deleteOrganizationAdmin = (e, adminId) => {
        e.preventDefault();
        dispatch({
            type: "DELETE_ORGANIZATION_ADMIN",
            organizationId: selectedOrganization._id,
            adminId
        })
    }

    const leaveTeam = () => {                
        setShowConfirmModal(false);
        dispatch({
            type: "DELETE_ORGANIZATION_ADMIN",
            organizationId: selectedOrganization._id,
            adminId: user._id,
            leave: true,
            history: history
        })    
    }

    const handleEditClick = (e, admin) => {
        e.preventDefault();
        setSelectedAdmin(admin);
        setShowEditModal(true);
    }

    const displayAdminRoleText = (role) => {
        switch (role) {
            case 0:
                return "Admin";
            case 1:
                return "Member";
            case 2:
                return "Collaborator";
            default:
                return "Unknown";
        }
    }

    return (
        <>
            {
                <>
                    <SwoopToast success={true} setShow={setShowInviteSuccess} show={showInviteSuccess} message="Permissions granted" />
                    <SwoopToast success={false} setShow={setShowInviteFailure} show={showInviteFailure} message="Failed to grant permissions" />
                    <SwoopToast success={true} setShow={setShowDeleteSuccess} show={showDeleteSuccess} message="User deleted" />
                    <SwoopToast success={false} setShow={setShowDeleteFailure} show={showDeleteFailure} message="Failed to delete user" />
                    <SwoopToast success={true} setShow={setShowUpdateSuccess} show={showUpdateSuccess} message="User updated" />
                    <SwoopToast success={false} setShow={setShowUpdateFailure} show={showUpdateFailure} message="Failed to update user" />
                    <div className="d-flex justify-content-between">
                        <h2>Access</h2>
                        <Button disabled={!admin} data-testid="add-permissions-button" className="d-none d-md-inline-block btn-right" onClick={() => setShowInviteModal(true)}>
                            Add User
                        </Button>
                    </div><div className="heading-underline mb-3 mb-md-4"></div>
                    <Button className="d-md-none mb-3" onClick={() => setShowInviteModal(true)} >
                        Add User
                    </Button>
                    {admin && (
                        <Form className="w-lg-60 fake-table">
                            <div className="row">
                                <div className="col-8 col-md-6 pr-2 pr-md-3">
                                    <Form.Label>Users</Form.Label>
                                </div>
                                <div className="col-4 col-md-3 pl-2 pl-md-3">
                                    <Form.Label>Role</Form.Label>
                                </div>
                            </div>                            
                            {organizationAdmins.map(admin => (
                                <Form.Group key={admin._id} className="mb-3" name="property-admin">
                                    <div className="row">
                                        <div className="col-8 col-md-6 pr-2 pr-md-3">
                                            <Form.Control value={admin.email} disabled />
                                        </div>
                                        <div className="col-4 col-md-3 pl-2 pl-md-3">
                                            <Form.Control className="attached-item" value={displayAdminRoleText(admin.role)} disabled />
                                        </div>
                                        {admin._id !== user._id &&
                                            <div className='col-3 mt-2 ml-1 m-md-0'>
                                                <button id={`edit-property-admin-${admin._id}`} className="icon-button mr-3" onClick={e => handleEditClick(e, admin)}>
                                                    <img src={pen} alt="pen-icon" />
                                                </button>
                                                <button id={`delete-property-admin-${admin._id}`} className="icon-button" onClick={e => deleteOrganizationAdmin(e, admin._id)}>
                                                    <img src={trashCan} alt="trash-can-icon" />
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </Form.Group>
                            ))}
                            <div className="heading-underline"></div>
                        </Form>)}

                    <div className="my-5">
                        <p className="warning-text">Leave Team</p>
                        <p className="subtext my-2">
                            Permanently leave this team? Leaving this team is irreversible.
                        </p>
                        <Button
                            className="warning-button"
                            onClick={() => setShowConfirmModal(true)}
                            disabled={false}
                            data-testid="delete-app-modal-button"
                        >
                            Leave Team
                        </Button>
                    </div>

                    <PermissionsModal
                        id="permissions-invite-modal"
                        show={showInviteModal}
                        onHide={() => setShowInviteModal(false)}
                        crud="create"
                        onSubmit={createOrganizationAdmin}
                    />
                    <PermissionsModal
                        id="permissions-edit-modal"
                        show={showEditModal}
                        onHide={() => setShowEditModal(false)}
                        crud="update"
                        onSubmit={updateOrganizationAdmin}
                        email={selectedAdmin ? selectedAdmin.email : ""}
                        role={selectedAdmin ? displayAdminRoleText(selectedAdmin.role) : ""}
                    />

                    <ConfirmModal   
                        id="confirm-modal"
                        show={showConfirmModal}
                        onCancel={() => setShowConfirmModal(false)}
                        onConfirm={leaveTeam}
                        title="Leave Team?"
                        message={<span>Are you sure you want to leave this team? <span className="text-danger">&nbsp;This action is irreversible.</span></span>}
                        ok="Yes"                    
                    />


                </>
      /* <SwoopToast success={true} setShow={setShowInviteSuccess} show={showInviteSuccess} message="Permissions granted" />
      <SwoopToast success={false} setShow={setShowInviteFailure} show={showInviteFailure} message="Failed to grant permissions" />
      <SwoopToast success={true} setShow={setShowUpdateSuccess} show={showUpdateSuccess} message="User updated" />
      <SwoopToast success={false} setShow={setShowUpdateFailure} show={showUpdateFailure} message="Failed to update user" />
      <SwoopToast success={true} setShow={setShowDeleteSuccess} show={showDeleteSuccess} message="User deleted" />
      <SwoopToast success={false} setShow={setShowDeleteFailure} show={showDeleteFailure} message="Failed to delete user" />
      <div className="d-flex justify-content-between">
        <h2>Permissions</h2>
        <Button data-testid="add-permissions-button" className="d-none d-md-inline-block btn-right" onClick={() => setShowInviteModal(true)} disabled={!propertyAdmin}>
          Add User
        </Button>
      </div>
      <div className="heading-underline mb-3 mb-md-4"></div>
      <Button className="d-md-none mb-3" onClick={() => setShowInviteModal(true)} disabled={!propertyAdmin}>
          Add User
        </Button>
      <Form className="w-lg-60 fake-table">
        <div className="row">
          <div className="col-8 col-md-6 pr-2 pr-md-3">
            <Form.Label>Users</Form.Label>
          </div>
          <div className="col-4 col-md-3 pl-2 pl-md-3">
            <Form.Label>Role</Form.Label>
          </div>
        </div>
        {propertyAdmins.map(admin => (
          <Form.Group key={admin._id} className="mb-3" name="property-admin">
            <div className="row">
              <div className="col-8 col-md-6 pr-2 pr-md-3">
                <Form.Control value={admin.email} disabled />
              </div>
              <div className="col-4 col-md-3 pl-2 pl-md-3">
                <Form.Control className="attached-item" value={displayAdminRoleText(admin.role)} disabled />
              </div>
              {propertyAdmin && admin._id !== user._id &&
                <div className='col-3 mt-2 ml-1 m-md-0'>
                  <button id={`edit-property-admin-${admin._id}`} className="icon-button mr-3" onClick={e => handleEditClick(e, admin)}>
                    <img src={pen} alt="pen-icon"/>
                  </button>
                  <button id={`delete-property-admin-${admin._id}`} className="icon-button" onClick={e => deletePropertyAdmin(e, admin._id)}>
                    <img src={trashCan} alt="trash-can-icon"/>
                  </button>
                </div>
              }
            </div>
          </Form.Group>
        ))}
      </Form>
      <PermissionsModal
        id="permissions-invite-modal" 
        show={showInviteModal} 
        onHide={() => setShowInviteModal(false)} 
        crud="create" 
        onSubmit={createPropertyAdmin}
      />
      <PermissionsModal
        id="permissions-edit-modal"  
        show={showEditModal} 
        onHide={() => setShowEditModal(false)} 
        crud="update" 
        onSubmit={updatePropertyAdmin}
        email={selectedAdmin ? selectedAdmin.email : ""}
        role={selectedAdmin ? displayAdminRoleText(selectedAdmin.role) : ""}
      /> */}
        </>
    )

}

export default Permissions;