import React, { useEffect, useState} from "react";
import { Card, Button } from "react-bootstrap";
import WelcomeModal from "views/components/modals/WelcomeModal.jsx";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { redirectToProperty } from 'utils/eventHandling';
import { truncateText } from 'utils/styleGenerators';
import { isOrganizationAdmin } from "utils/permissions";

const Dashboard = props => {  
  const seenWelcome = useSelector(state => state.auth.seenWelcome);  
  const dispatch = useDispatch();
  const history = useHistory();

  const properties = useSelector(state => state.dashboard.properties) || [];
  const selectedOrganization = useSelector(state => state.organizations.selected);
  const [filteredProperties, setFilteredProperties] = useState([...properties]);

  const user = useSelector(state => state.auth.user);
  let oadmin = isOrganizationAdmin(user, selectedOrganization, 0);

  useEffect(() => {
    let filtered = properties.filter(property => property.organization === selectedOrganization._id);    
    setFilteredProperties(filtered);
  }, [properties, selectedOrganization])

  useEffect(() => {
    dispatch({ type: "PROPERTIES_FETCH_ALL" });
  }, []);

  return (
    <>
      <div className="row p-lg-6">
        {oadmin && <>
        <div className="col-12 col-md-4 card-wrapper">
          <a href="/#/admin/new-property">
            <Card className="d-none d-lg-block">
              <Card.Body className="text-center pt-5">
                <p className="bold-green fake-link">+ Add New App</p>
              </Card.Body>
            </Card>
            <Button className="w-100 py-3 d-lg-none">+ Add New App</Button>
          </a>
        </div>
        </>}
        { filteredProperties.some(property => !property.settings) ? 
          null
          :
          filteredProperties.map(property => {
            return (
              <div name="property-card" key={property._id} className="col-12 col-md-4 card-wrapper">
                <Card id={`property-card-${property._id}`} onClick={e => redirectToProperty(e, property, dispatch, history)}>
                  <Card.Body className="text-center">
                    {/* <img src={property.settings.PROPERTY_LOGO_URL} alt="app logo" className="mb-2 logo-thumbnail" /> */}
                    <Card.Title>{truncateText(property.name, 40)}</Card.Title>
                    <Card.Text className="subtext">
                      {truncateText(property.url, 30)}
                    </Card.Text>
                  </Card.Body>
                  <Button 
                  >
                    Edit App
                  </Button>
                </Card>
              </div>
            )}
          )
        } 
      </div>
      {!seenWelcome ? <WelcomeModal /> : null}
    </>
  );
}

export default Dashboard;
