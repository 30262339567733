import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { redirectToProperty, redirectToOrganization } from 'utils/eventHandling';
import { Navbar, Nav, Dropdown } from "react-bootstrap";
import logo from "assets/images/1-Click-Login_2color.svg";
import caret from "assets/images/caret-down-green.svg";
import arrow from "assets/images/arrow-right.svg";
import slayer from 'assets/images/slayer-icon-bw.svg';
import avatar from 'assets/images/swoop-avatar.svg';
import hamburger from 'assets/images/hamburger-nav-icon.svg';
import { isOrganizationAdmin } from "utils/permissions";

const AdminNavbar = props => {
  const user = useSelector(state => state.auth.user);
  const properties = useSelector(state => state.dashboard.properties);
  const selectedProperty = useSelector(state => state.dashboard.selectedProperty);
  const selectedOrganization = useSelector(state => state.organizations.selected);
  const [filteredProperties, setFilteredProperties] = useState([])
  const [filteredOrganizations, setFilteredOrganizations] = useState([])
  const organizations = useSelector(state => state.organizations.all);  

  const history = useHistory();
  const dispatch = useDispatch();

  let oadmin = isOrganizationAdmin(user, selectedOrganization, 0);

  useEffect(() => {
    if(selectedOrganization) {
      setFilteredProperties(properties.filter(property => property.organization === selectedOrganization._id));
      setFilteredOrganizations(organizations);
    }
    // let filtered = properties.filter(property => property.organization === selectedOrganization._id && (selectedProperty ? property._id !== selectedProperty._id : true));
    // setFilteredProperties(filtered);

    // let filteredOrgs = organizations.filter(organization => organization._id !== selectedOrganization._id);
    // setFilteredOrganizations(filteredOrgs);    
  }, [properties, organizations, selectedOrganization, selectedProperty])

  const isShared = (organization) => {
    let shared = organization && organization.organizationAdmins &&
      organization.organizationAdmins.length > 0 &&
      organization.organizationAdmins[0].admin !== user._id && 
      organization.personal === true;
    return shared ? <span className="shared" style={{color: "gray"}}>(shared)</span> : ""
    
  }

  return (
    <Navbar expand="lg">
      <Navbar.Brand className="logo" href="/#/admin/dashboard">
        <img src={logo} alt="swoop-logo" className="d-inline-block align-top" />
      </Navbar.Brand>
      <Dropdown id="mobile-nav-dropdown" className="d-lg-none position-static">
        <Dropdown.Toggle variant="success" className="not-button">
          <img src={hamburger} />
        </Dropdown.Toggle>
        <Dropdown.Menu className="mobile-nav-dropdown-menu">
         
            { /* ORGANIZATION Dropdown */}
          {selectedOrganization &&
          <>
          {filteredOrganizations.map((resource, idx) => (              
                <Dropdown.Item
                  key={idx}
                  className={idx === 0 ? 'top-item' : ''}
                  onClick={e => redirectToOrganization(e, resource, dispatch, history)}
                >                  
                  {resource["name"] + " "} 
                  {isShared(resource)}
                </Dropdown.Item>
              ))}
              {
                <>
                {filteredOrganizations.length !== 0 && 
                  <Dropdown.Divider className="last-dropdown-divider" />
                }
                  <span onClick={() => history.push('/admin/new-organization')} className="bold-green bottom-item dropdown-item fake-button">
                    + Add Team
                  </span>
                </>
              }
          </>}
          
          <Dropdown.Divider />      
          <Dropdown.Item href="/#/admin/user/profile">Profile</Dropdown.Item>
          <Dropdown.Divider />          
          <Dropdown.Item href="/#/logout" className="align-items-middle">
            <img src={arrow} alt="right arrow" className="mr-2 w-15px" />
            Sign Out
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Navbar.Collapse id="main-nav" className="justify-content-end d-none d-lg-block">
        <Nav className="mr-auto">

          { /* ORGANIZATION Dropdown */}
          {selectedOrganization &&
          <>
          <Dropdown>
            <Dropdown.Toggle variant="success" id="organization-dropdown" className={`not-button`}>
              <Nav.Item>
                <Navbar.Text>{selectedOrganization.name} {isShared(selectedOrganization)} <img src={caret} alt="down caret" className="mx-1" /></Navbar.Text>
              </Nav.Item>
            </Dropdown.Toggle>
            <Dropdown.Menu className="search-dropdown">
              {filteredOrganizations.map((resource, idx) => (
                <Dropdown.Item
                  key={idx}
                  className={idx === 0 ? 'top-item' : ''}
                  onClick={e => redirectToOrganization(e, resource, dispatch, history)}
                >
                  {resource["name"] + " "} 
                  {isShared(resource)}
                </Dropdown.Item>
              ))}
              {
                <>
                  {filteredOrganizations.length !== 0 && 
                    <Dropdown.Divider className="last-dropdown-divider" />
                  }
                  <span onClick={() => history.push('/admin/new-organization')} className="bold-green bottom-item dropdown-item fake-button">
                    + Add Team
                  </span>
                </>
              }
            </Dropdown.Menu>
          </Dropdown>
          </>}

          { /* PROPERTIES Dropdown */}
          {selectedProperty && 
          <>
          <Dropdown>
            <Dropdown.Toggle variant="success" id="properties-dropdown" className={`not-button`}>
              <Nav.Item>
                <Navbar.Text>{selectedProperty.name} <img src={caret} alt="down caret" className="mx-1" /></Navbar.Text>
              </Nav.Item>
            </Dropdown.Toggle>
            <Dropdown.Menu className="search-dropdown">
              {filteredProperties.map((resource, idx) => (
                <Dropdown.Item
                  key={idx}
                  className={idx === 0 ? 'top-item' : ''}
                  onClick={e => redirectToProperty(e, resource, dispatch, history)}
                >
                  {resource["name"]}
                </Dropdown.Item>
              ))}
              {oadmin &&
                <>
                  {filteredProperties.length !== 0 && 
                    <Dropdown.Divider className="last-dropdown-divider" />
                  } 
                  <span onClick={(e) => history.push('/admin/new-property')} className="bold-green bottom-item dropdown-item fake-button">
                    + Add App
                  </span>
                </>
              }
            </Dropdown.Menu>
            </Dropdown>
            </>}

        </Nav>
      </Navbar.Collapse>
      <Navbar.Collapse className="justify-content-end">
        <Nav.Item>
          <Nav.Link href="https://docs.swoopnow.com/">Documentation</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="https://swoopnow.com/support/">Support</Nav.Link>
        </Nav.Item>
        <Dropdown id="user-dropdown" className="align-items-middle mx-2">
          <Dropdown.Toggle variant="success" className="not-button">
            <img src={avatar} alt="swoop avatar" className="mx-1" />
            <img src={caret} alt="down caret" className="mx-1" />
          </Dropdown.Toggle>
          <Dropdown.Menu align="right" className="top-caret">
            <Dropdown.Item href="/#/admin/user/profile" className="text-center top-item">
              <img src={slayer} alt="password slayer icon" className="my-2" />
              {user.firstName && user.lastName ? <p>{`${user.firstName} ${user.lastName}`}</p> : ""}
              {user.email ? <p className="subtext">{user.email}</p> : ""}
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item href="/#/admin/user/profile">Profile</Dropdown.Item>
            <Dropdown.Divider />            
            <Dropdown.Item href="/#/logout" className="align-items-middle bottom-item">
              <img src={arrow} alt="right arrow" className="mr-2 w-15px" />
              Sign Out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

      </Navbar.Collapse>
    </Navbar>
  );
}

export default AdminNavbar;
