export const isOrganizationAdmin = (user, organization, role = 0) => {
    if(!organization || !user || !organization.organizationAdmins) return false;
    let orgAdmin = organization.organizationAdmins.filter(oa => oa.admin === user._id && oa.role === role);        
    if(orgAdmin.length === 0) return false;    
    return orgAdmin;
}

export const isPropertyAdmin = (user, organization, property, role = 0) => {
    if(!organization || !user || !organization.organizationAdmins || !property) return false;
    let orgAdmin = organization.organizationAdmins.find(oa => oa.admin === user._id);
    let propertyRole = orgAdmin.propertyRoles.find(pr => pr.property === property._id && role === role);     
    return propertyRole;
}