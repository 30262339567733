import mixpanel from 'mixpanel-browser';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

export const useTrackClicks = (elements=[]) => {
  const user = useSelector(state => state.auth.user || {});

  useEffect(() => {
    mixpanel.identify(user._id);

    for(const element of elements){
      const node = document.getElementById(element.id);
      if(node){
        node.addEventListener('click', (e) => {
          if(e.target.nodeName !== "LABEL"){
            mixpanel.track(`${element.eventName}`, { ...element.properties, Site: "Dashboard" })
          }
        })
      }
    }
  }, [])
}

export const useTrackViews = () => {
  const stallLimit = 120000;
  const history = useHistory();
  let priorPathname = history.location.pathname
  let viewStartTime = Date.now();

  const trackView = (eventTrigger, page) => {
    let timePassed = Date.now() - viewStartTime;
    let realEventTrigger = eventTrigger;
    let duration = timePassed/1000

    if(eventTrigger === "blur" && timePassed > stallLimit){
      realEventTrigger = "stall";
      duration = 0;
    }

    mixpanel.track(`Viewed ${page} Page`, {
      'Duration (sec)': duration,
      Site: "Dashboard",
      'Event Trigger': realEventTrigger
    });
  }

  const pathnameToPage = (pathname) => {
    switch(pathname){
      case "/":
        return 'Home';
      case "/admin/dashboard":
        return 'Home';
      case "/admin/new-property":
        return 'New App';
      case "/admin/property":
        return 'Settings';
      case "/admin/property/settings":
        return 'Settings';
      case "/admin/property/credentials":
        return "Credentials";
      case "/admin/property/branding":
        return "Branding";
      case "/admin/property/options":
        return "Options";
      case "/admin/property/mfa":
        return "MFA";
      case "/admin/property/permissions":
        return "Permissions";
      case "/admin/user/profile":
        return "User Profile";
      case "/admin/user/billing":
        return "Billing"
    }
  }

  useEffect(() => {
    history.listen(location => {
      const priorPage = pathnameToPage(priorPathname);
      const currentPage = pathnameToPage(location.pathname);

      if(priorPage !== currentPage){
        trackView('user clicked nav', priorPage);
        priorPathname = location.pathname
        viewStartTime = Date.now();
      }
    })
  }, [history])

  useEffect(() => {
    let didBlur = false;
    window.addEventListener('blur', () => {
      const page = pathnameToPage(history.location.pathname)
      if(!didBlur){
        trackView('blur', page);
        didBlur = true;
      }
    })
  }, [])

}
