
import { call, put, take, all, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { errorHandler } from 'utils/sagas';

const DASHBOARD_API_BASE_URL = process.env.REACT_APP_DASHBOARD_API_ENDPOINT;
const DASHBOARD_API_ENDPOINT = `${DASHBOARD_API_BASE_URL}/api/admins`;
const DASHBOARD_API_SWOOP_URL = `${DASHBOARD_API_BASE_URL}/api/auth`;

// worker sagas
export function* verifyAdmin(action){
  let redirectUri = process.env.SWOOP_DASHBOARD_REDIRECT_URI;
  let body = {
    token: action.token
  }

  let url = `${DASHBOARD_API_SWOOP_URL}/swoop`;
  
  try {
    const {data} = yield call(axios.post, url, body );    
    const admin = data.admin;
    const access_token = data.tokens.access.token;
    const refresh_token = data.tokens.refresh.token;

    yield all([
      yield put({ type: "COMPLETE_VERIFICATION", payload: { user: admin, access_token, refresh_token } }),      
      yield put({ type: "FETCH_ORGANIZATIONS", payload: {} })
    ]);    
  } catch (error) {
    console.log(error);
  }
}

export function* refreshAuthTokens(action){
  console.log('refreshing...........')
  let body = {
    refresh_token: action.refresh_token,
    grant_type: "refresh_token"
  }

  let url = `${DASHBOARD_API_SWOOP_URL}/token`;

  try {
    const { data } = yield call(axios.post, url, body );
    const access_token = data.tokens.access.token;
    const refresh_token = data.tokens.refresh.token;
    yield put({ type: "REFRESHED_AUTH_TOKENS", payload: { access_token, refresh_token } });
    yield put({ type: "FETCH_ORGANIZATIONS", payload: {} });

  } catch (error) {
    console.log(error);
    yield put({ type: "LOGOUT" });    
  }
}

export function* completeRegistration(){
  try {
    let url = `${DASHBOARD_API_ENDPOINT}`;
    yield call(axios.post, url, { meta: {SEEN_WELCOME: true}});
    yield put({ type: "UPDATE_ADMIN_META", payload: {SEEN_WELCOME: true} });
  } catch (error) {
    console.log(error);
  }
}

export function* updateAdmin(action){
  // try {
    
  // } catch (error) {
  //   console.log(error);
  // }
  const { data: {admin: updatedAdmin} } = yield call(axios.put, DASHBOARD_API_ENDPOINT, action.params);
  yield put({ type: "UPDATE_ADMIN_LOCALLY", payload: updatedAdmin});
}

export function* authSaga() {  
  yield takeLatest("COMPLETE_REGISTRATION", completeRegistration);
  yield takeLatest("UPDATE_ADMIN", errorHandler(updateAdmin,  "UPDATE_LOAD_STATUS", "adminUpdateStatus"));
  yield takeLatest("VERIFY_ADMIN", verifyAdmin);
  yield takeLatest("REFRESH_AUTH_TOKENS", refreshAuthTokens);
}
