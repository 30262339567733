import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import UserProfileForm from 'views/components/forms/UserProfileForm';
import SwoopToast from 'views/components/SwoopToast';
import { handleLoadToasts } from "utils/eventHandling";

const Profile = () => {
  const { firstName, lastName, email, phoneNumber, companySize } = useSelector(state => state.auth.user)
  const loadStatus = useSelector(state => state.dashboard.loadStatuses && state.dashboard.loadStatuses.adminUpdateStatus);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showFailureToast, setShowFailureToast] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = (firstName, lastName, phoneNumber, companySize) => {
    dispatch({ type: "UPDATE_ADMIN", 
      params: { firstName, lastName, phoneNumber, companySize }
    })    
  }

  useEffect(() => {
    handleLoadToasts(loadStatus, setShowSuccessToast, setShowFailureToast);
  }, [loadStatus])

  return (
    <>
      <SwoopToast success={true} setShow={setShowSuccessToast} show={showSuccessToast} message="Profile Updated" />
      <SwoopToast success={false} setShow={setShowFailureToast} show={showFailureToast} message={"Failed to Update Profile"} />

      <h2>Profile</h2>
      <div className="heading-underline"></div>
      <div className="row pb-4 pb-md-0">
        <div className="col-12 col-md-5">
          <UserProfileForm 
            onSubmit={onSubmit} 
            firstName={firstName}
            lastName={lastName}
            email={email}
            phoneNumber={phoneNumber}
            companySize={companySize}
            crud="update"
          />
        </div>        
        <div className="col-7 d-none d-md-flex justify-content-center align-items-start pt-5">
        <div className="w-md-60 contact-us-wrapper">
            <h5>Welcome to 1-Click Login!</h5>
            <p className="subtext">
              Providing this info will up us better assist you and your team as you trust us with this mission critical service.
            </p>
            <br />
            <a href="https://swoopnow.com/support/">Contact Support with any questions</a>
          </div>
        </div>  
      </div>
    </>
  )
}

export default Profile;