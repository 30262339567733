import React, { useState, useEffect } from "react";
import { Form, Button, Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import branding from 'assets/images/branding-icon-filled.svg';

const ConfirmModal = (props) => {
    const dispatch = useDispatch();    
    const titleText = props.title || "Confirm";
    const messageText = props.message || "Are you sure?";
    const okText = props.ok || "OK";
    const cancelText = props.cancel === null || props.cancel === undefined ? "Cancel" : props.cancel;

    const okButtonClick = (e) => {
        e.preventDefault();        
        props.onConfirm();
    }
    
    const cancelButtonClick = (e) => {
        e.preventDefault();
        props.onCancel();
    }

    return (
        <Modal centered show={props.show} onHide={props.onCancel}>
            <Modal.Header closeButton className="mt-2 justify-content-center">
                <img src={branding} alt="checkmark-icon" className="w-10 t-n110" />
            </Modal.Header>
            <Modal.Body className="pt-0">
                <Form noValidate onSubmit={(e) => e.preventDefault()}>                    
                        <h2>{titleText}</h2>
                        <Form.Group className="mb-3" controlId="">
                            <Form.Text className="subtext">
                                {messageText}
                            </Form.Text>                            
                        </Form.Group>                                                               
                    <Form.Group className="mb-3" controlId="">
                        <div className="d-flex justify-content-between">
                        <Button className="button-primary"
                            data-testid="button-ok"
                            onClick={okButtonClick}
                            type="submit">
                                {okText}
                        </Button>&nbsp;
                        {cancelText && cancelText.length > 0 && 
                        <Button className="warning-button"
                            data-testid="button-cancel"
                            onClick={cancelButtonClick}
                            type="submit">
                                {cancelText}
                        </Button>
                        }   
                        </div>
                    </Form.Group>
                </Form>
            </Modal.Body>
        </Modal>)
}
export default ConfirmModal;
