import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { HashRouter } from "react-router-dom";
import { Routes }  from "views/layouts/BaseRoutes";
import {createBrowserHistory} from "history";
import Mixpanel from 'mixpanel/Mixpanel';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import ErrorBoundary from 'views/components/ErrorBoundary';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const App = ({ store, persistor }) => {
  const history = createBrowserHistory();

  history.listen((location, action) => {
      window.scrollTo(0, 0)
  })

  return (
    /* Provide Redux store */
    <ErrorBoundary>
    <Provider store = { store } >
      <PersistGate persistor = { persistor } loading = { null } >
        <HashRouter history={history} >
          <Elements stripe={stripePromise}>            
            <Routes />            
          </Elements>
          <Mixpanel history={history}/>
        </HashRouter>
      </PersistGate>
    </Provider>
    </ErrorBoundary>
  );
}

//<Mixpanel history={history}/> (goes after Routes)

export default App;
