import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    CardElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";

export default function CheckoutForm({ onSuccess, onFailure }) {
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState('');
    const [disabled, setDisabled] = useState(true);
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const selectedOrganization = useSelector(state => state.organizations.selected);
    const user = useSelector(state => state.auth.user);
    const loadStatus = useSelector(state => state.dashboard.loadStatuses && state.dashboard.loadStatuses.billingUpdateStatus);

    useEffect(() => {
        if (loadStatus === 'failed') {
            setError('Your card was declined. Please try again.');
            setProcessing(false);
        }
    }, [loadStatus]);

    const cardStyle = {
        style: {
            base: {
                color: "#0E72EF",
                fontFamily: 'Lato, Helvetica Neue, Arial, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#32325d"
                }
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        }
    };

    const handleChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        console.log(event)
        setDisabled(event.empty || event.error || !event.complete);
        setError(event.error ? event.error.message : "");
    };

    const handleSubmit = async ev => {
        ev.preventDefault();

        // if(!billing.clientSecret) {
        //   alert('something went wrong');
        //   return;
        // }

        setProcessing(true);

        // const payload = await stripe.confirmCardPayment(billing.clientSecret, {
        //   payment_method: {
        //     card: elements.getElement(CardElement)
        //   }
        // });

        // create a stripe credit card token
        // const payload = await stripe.createToken(elements.getElement(CardElement));
        const payload = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement)
        });

        if (payload.error) {
            setError(`${payload.error.message}`);
            setProcessing(false);
            if (onFailure) {
                onFailure();
            }
        } else {
            setError(null);            
            dispatch({ type: 'BILLING_UPDATE', payload: { paymentMethod: payload.paymentMethod, organizationId: selectedOrganization._id } });
        }
    };

    return (

        <span className="stripe">
            <form id="payment-form" onSubmit={handleSubmit}>
                <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
                <button
                    className="btn btn-primary"
                    disabled={processing || disabled || succeeded}
                    id="submit"
                >
                    <span className="button-text">
                        {processing ? (
                            <>
                                <span className="spinner-border spinner-border-sm text-light" role="status" aria-hidden="true"></span>
                                &nbsp;Processing...
                            </>

                        ) : (
                            "Add Card"
                        )}
                    </span>
                </button>
                {/* Show any error that happens when processing the payment */}
                {error && (
                    <div className="card-error" role="alert">
                        {error}
                    </div>
                )}
                {/* Show a success message upon completion */}
                <p className={succeeded ? "result-message" : "result-message hidden"}>
                    Successfully added card
                </p>
            </form>

        </span>
    );
}
