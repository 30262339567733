const initalState = {
  paymentMethod: null,
  admin: null,
  organization: null,
  customer: null,
  invoices: [],
  intent: null,
  paymentIntents: [],
  charges: [],
};

const billingReducer = (state = initalState, action) => {
    switch( action.type ) {
      case "BILLING_FULFILL": {
        if(!action.payload) {
          return initalState;
        }
        return { ...state,
          paymentMethod: action.payload.paymentMethod,
          admin: action.payload.admin,
          organization: action.payload.organization,
          invoices: action.payload.invoices,
          customer: action.payload.customer,
          paymentIntents: action.payload.paymentIntents,
          charges: action.payload.charges,
         };
      }

      case "BILLING_PAYMENT_INTENT_FULFILL": {
        if(!action.payload) {
          return {...state, intent: null};
        }
        return { ...state,
          intent: action.payload.intent,
          };
      }
      
      case "LOGOUT": {
        return initalState;
      }
      default:
        return state;
    }
  }

export default billingReducer
