import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Settings from '../pages/properties/Settings';
import Credentials from '../pages/properties/Credentials';
import Branding from '../pages/properties/Branding';
import AuthFlow from '../pages/properties/AuthFlow';
import MFA from '../pages/properties/MFA';
import Permissions from '../pages/properties/Permissions';
import Analytics from 'views/pages/properties/Analytics';
import PropertySubNav from '../components/nav/PropertySubNav';

const PropertyLayout = () => {
  const property = useSelector(state => state.dashboard.selectedProperty);
  const match = useRouteMatch();

  return (
    <>
      <div className="row title-banner d-lg-none">
        {property && (<PropertySubNav mobile property={property} />)}
      </div>
      <div className="row title-banner d-none d-lg-flex">{property && property.name}</div>
      <div className="row p-lg-5">
        <div className="d-none d-lg-flex col col-lg-3">
          {property && (<PropertySubNav desktop />) }
        </div>
        <div className="col col-12 col-lg-9 pt-3 pt-lg-0">
          <Switch>          
          <Route path={`${match.path}/analytics`} render={props => <Analytics {...props} />}/>
            {/* <Route exact path={`${match.path}/settings`} render={props => <Settings {...props} />}/> */}
            <Route path={`${match.path}/settings`} render={props => <Settings {...props} />}/>
            <Route path={`${match.path}/credentials`} render={props => <Credentials {...props} />}/>
            <Route path={`${match.path}/branding`} render={props => <Branding {...props} />}/>
            <Route path={`${match.path}/options`} render={props => <AuthFlow {...props} />}/>
            <Route path={`${match.path}/mfa`} render={props => <MFA {...props} />}/>
            <Route path={`${match.path}/permissions`} render={props => <Permissions {...props} />}/>            
            <Route path={`${match.path}/`} render={props => <Analytics {...props} />}/>
          </Switch>
        </div>
      </div>
    </>
  )
}

export default PropertyLayout;
