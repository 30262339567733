import React from 'react';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import UserSubNav from '../components/nav/UserSubNav';
import Profile from '../pages/users/Profile';

const UserLayout = () => {
  const match = useRouteMatch();

  return (
    <div className="row p-lg-5">
      <div className="d-none d-lg-flex col col-md-1-fifth">
        <UserSubNav desktop />
      </div> 
      <div className="col col-12 col-lg-4-fifths pt-3 pt-lg-0">
        <Switch>
          <Route exact path={`${match.path}/`} render={props => <Profile {...props} />}/>
          <Route path={`${match.path}/profile`} render={props => <Profile {...props} />}/>          
        </Switch>
      </div>
    </div>
  )
}

export default UserLayout;