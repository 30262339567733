import mixpanel from 'mixpanel-browser';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const useUpdateMfaInfo = () => {
  const selectedPropertyMfa = useSelector(state => state.dashboard.selectedProperty && state.dashboard.selectedProperty.mfa || {});
  const mfaType = useSelector(state => state.dashboard.selectedProperty && state.dashboard.selectedProperty.mfa && state.dashboard.selectedProperty.mfa.pluginDetails && state.dashboard.selectedProperty.mfa.pluginDetails.name)
  const mfaLoadStatus = useSelector(state => state.dashboard.loadStatuses && state.dashboard.loadStatuses.mfaLoadStatus);

  // This is a little bit of a hack. Could not find a better way to assign initial store state other than
  // the useState hook.
  const [initialMfaEnabled, setInitialMfaEnabled] = useState(selectedPropertyMfa.enabled); 
  const [initialMfaType, setInitialMfaType] = useState(mfaType);

  useEffect(() => {
    switch(mfaLoadStatus){
      case "success":
        if(selectedPropertyMfa.enabled === true) {
          mixpanel.people.increment('Total MFA Enabled', 1);
        } else if(selectedPropertyMfa.enabled === false) {
          mixpanel.people.increment('Total MFA Enabled', -1);
        }
        mixpanel.track('Update Mfa', {
          Site: "Dashboard",
          View: "MFA",
          'Initial MFA Enabled': initialMfaEnabled,
          'Initial MFA Type': initialMfaType,
          'Updated MFA Enabled': selectedPropertyMfa.enabled, 
          'Updated MFA Type': mfaType
        })
        break;
    }
  }, [mfaLoadStatus])
}

export const useUpdateAuthFlow = () => {
  const user = useSelector(state => state.auth.user || {});
  const authFlowLoadStatus = useSelector(state => state.dashboard.loadStatuses && state.dashboard.loadStatuses.propertyLoadStatus);
  const authFlow = useSelector(state => state.dashboard.selectedProperty && state.dashboard.selectedProperty.settings && state.dashboard.selectedProperty.settings.MAGIC_FLOW);
  const [initialAuthFlow, setInitialAuthFlow] = useState(authFlow);

  mixpanel.identify(user._id);

  useEffect(() => {
    if(authFlowLoadStatus === "success"){
      switch(authFlow){
        case "MAGIC_MESSAGE_MAGIC_LINK":
          mixpanel.people.increment('Total Magic Message Enabled', 1);
          break;
        case "MAGIC_LINK_MAGIC_MESSAGE":
          mixpanel.people.increment('Total Magic Link + Message Enabled', 1);
          break;
        case "MAGIC_LINK":
          mixpanel.people.increment('Total Magic Link Enabled', 1);
          break;
      }

      switch(initialAuthFlow){
        case "MAGIC_MESSAGE_MAGIC_LINK":
          mixpanel.people.increment('Total Magic Message Enabled', -1);
          break;
        case "MAGIC_LINK_MAGIC_MESSAGE":
          mixpanel.people.increment('Total Magic Link + Message Enabled', -1);
          break;
        case "MAGIC_LINK":
          mixpanel.people.increment('Total Magic Link Enabled', -1);
          break;
      }

      mixpanel.track('Update Auth Flow', {
        Site: "Dashboard",
        View: "Auth Flow",
        'Initial Auth Flow': initialAuthFlow,
        'Updated Auth Flow': authFlow 
      })
    }
  }, [authFlowLoadStatus])
}

export const useUpdateProperty = () => {
  const loadStatus = useSelector(state => state.dashboard.loadStatuses && state.dashboard.loadStatuses.propertyLoadStatus);
  const property = useSelector(state => state.dashboard.selectedProperty || {});
  const [initialProperty] = useState([property.name, property.url])

  useEffect(() => {
    if(loadStatus === "success"){
      mixpanel.people.remove('Properties', initialProperty);
      mixpanel.people.append('Properties', [property.name, property.url]);
    }
  }, [loadStatus])
}