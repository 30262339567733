import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";

// Redux
import store, { persistor } from "./store/store";
import App from "./App";

// Needed to plug axios and send token
import { setupAxios } from "./utils";

import "bootstrap/dist/css/bootstrap.min.css";
import 'react-phone-input-2/lib/style.css'
import "assets/sass/swoop.scss";

setupAxios(axios, store);

ReactDOM.hydrate(
  <App
    store={store}
    persistor={persistor}
    />,
  document.getElementById("root")
);
