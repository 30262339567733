export const isActive = (currentLocation, matchingUrls) => {
  if(matchingUrls.some(url => url === currentLocation.pathname)){
    return 'active';
  } else {
    return 'not-active';
  }
}

export const truncateText = (text, charLimit) => {
  let truncatedText = text.slice(0, charLimit);
  truncatedText = text.length > charLimit ? truncatedText.concat('...') : truncatedText;
  return truncatedText;
}