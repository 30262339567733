import { call, put, takeLatest, select } from "redux-saga/effects";
import axios from "axios";
import { errorHandler } from 'utils/sagas';
import {isOrganizationAdmin} from 'utils/permissions';

const DASHBOARD_API_BASE_URL = process.env.REACT_APP_DASHBOARD_API_ENDPOINT;
const DASHBOARD_API_ENDPOINT = `${DASHBOARD_API_BASE_URL}/api`;

export function* fetchOrganizationAdmins(action) {    
    let url = `${DASHBOARD_API_ENDPOINT}/organizations/${action.organizationId}/admins`;
    try {
        const { data: organizationAdmins } = yield call(axios.get, url);
        yield put({ type: "FULFILL_ORGANIZATION_ADMINS", payload: organizationAdmins })

        let user = yield select(state => state.auth.user);
        let organization = yield select(state => state.organizations.selected);
                
        if(isOrganizationAdmin(user, organization)) {
            yield put({ type: "BILLING_GET", payload: {organizationId: action.organizationId }});
        }

    } catch (error) {
        console.log(error);
    }
}

export function* deleteOrganizationAdmin(action) {
    let url = `${DASHBOARD_API_ENDPOINT}/organizations/${action.organizationId}/admins/${action.adminId}`;
    const { data: respObj } = yield call(axios.delete, url);
    if(respObj) {        
        yield put({ type: "FULFILL_DELETED_ORGANIZATION_ADMIN", payload: action.adminId })
        yield put({ type: "FETCH_ORGANIZATIONS", payload: {} });
        yield put({ type: "SELECT_PERSONAL_ORGANIZATION", payload: {leave:true} });
    }
}

export function* createOrganizationAdmin(action) {    
    let url = `${DASHBOARD_API_ENDPOINT}/organizations/${action.organizationId}/admins`;
    const { data: organizationAdmin } = yield call(axios.post, url, action.params);
    yield put({ type: "FETCH_ORGANIZATION_ADMINS", organizationId: action.organizationId});
}

export function* updateOrganizationAdmin(action){
    let url = `${DASHBOARD_API_ENDPOINT}/organizations/${action.organizationId}/admins/${action.adminId}`;
    const { data: respObj } = yield call(axios.put, url, action.params);
    yield put({ type: "FETCH_ORGANIZATION_ADMINS", organizationId: action.organizationId});
  }
  

export function* organizationAdminSaga() {
    yield takeLatest("FETCH_ORGANIZATION_ADMINS", fetchOrganizationAdmins);
    yield takeLatest(
        "DELETE_ORGANIZATION_ADMIN",
        errorHandler(deleteOrganizationAdmin, "UPDATE_LOAD_STATUS", "permissionsDeletedStatus")
    );
    yield takeLatest(
        "CREATE_ORGANIZATION_ADMIN",
        errorHandler(createOrganizationAdmin, "UPDATE_LOAD_STATUS", "permissionsGrantedStatus")
    );
    yield takeLatest(
        "UPDATE_ORGANIZATION_ADMIN",
        errorHandler(updateOrganizationAdmin, "UPDATE_LOAD_STATUS", "permissionsUpdatedStatus")
    );
}