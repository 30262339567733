import React, { useEffect } from 'react';
import { useRouteMatch, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Dropdown, ListGroup } from "react-bootstrap";
import { isActive } from 'utils/styleGenerators';
import throne from 'assets/images/settings-icon.svg';
import throneFilled from 'assets/images/settings-icon-filled.svg';
import padlock from 'assets/images/mfa-icon.svg';
import key from 'assets/images/permissions-icon.svg';
import keyFilled from 'assets/images/permissions-icon-filled.svg';
import downCaret from 'assets/images/caret-down-green.svg';
import treasureChest from 'assets/images/billing-icon.svg';
import treasureChestFilled from 'assets/images/billing-icon-filled.svg';
import flag from 'assets/images/flag-icon.svg';
import flagFilled from 'assets/images/flag-icon-filled.svg';
import { isOrganizationAdmin } from 'utils/permissions';

const OrganizationSubNav = ({ desktop, mobile, organization }) => {
    const user = useSelector(state => state.auth.user);
    const selectedOrganization = useSelector(state => state.organizations.selected);
    const organizations = useSelector(state => state.organizations);
    let admin = isOrganizationAdmin(user, selectedOrganization);

    const admins = useSelector(state => state.organizations.admins);
    let personal = selectedOrganization ? selectedOrganization.personal && admin : false;

    const match = useRouteMatch();
    const location = useLocation();
    const propertiesPath = `${match.path}`;
    const settingsPath = `${match.path}/settings`;
    const permissionsPath = `${match.path}/permissions`;
    const billingPath = `${match.path}/billing`;

    useEffect(() => {
        if(selectedOrganization) {
            admin = isOrganizationAdmin(user, selectedOrganization);
            personal = selectedOrganization.personal;
        }
    }, [selectedOrganization, admins, organizations])

    if (desktop) {
        return (
            <ListGroup className="subnav">
                <ListGroup.Item action href={`/#${propertiesPath}`} className={isActive(location, [propertiesPath, match.path])} >
                    <img src={flag} alt="flag-icon" className="subnav-icon default" />
                    <img src={flagFilled} alt="flag-icon" className="subnav-icon active" />
                    Apps
                </ListGroup.Item>
                <ListGroup.Item action href={`/#${settingsPath}`} className={isActive(location, [settingsPath])}>
                    <img src={throne} alt="throne-icon" className="subnav-icon default" />
                    <img src={throneFilled} alt="throne-icon" className="subnav-icon active" />
                    Settings
                </ListGroup.Item>
                {!personal && <>
                    <ListGroup.Item action href={`/#${permissionsPath}`} className={isActive(location, [permissionsPath])}>
                        <img src={key} alt="key-icon" className="subnav-icon default" />
                        <img src={keyFilled} alt="key-icon" className="subnav-icon active" />
                        Access
                    </ListGroup.Item>
                </>}
                {admin &&
                    <>
                        <ListGroup.Item action href={`/#${billingPath}`} className={isActive(location, [billingPath])}>
                            <img src={treasureChest} alt="treasure-chest-icon" className="subnav-icon default" />
                            <img src={treasureChestFilled} alt="treasure-chest-icon" className="subnav-icon active" />
                            Billing
                        </ListGroup.Item>
                    </>}
            </ListGroup>
        )
    } else if (mobile) {
        return (
            <Dropdown id="mobile-subnav-dropdown" className="subnav">
                <Dropdown.Toggle variant="success" className="not-button text-left w-100">
                    {organization ? organization.name : "Loading..."}
                    <img src={downCaret} alt="down caret" className="ml-2" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="mobile-nav-dropdown-menu">
                    <Dropdown.Item href={`/#${propertiesPath}`}>
                        <img src={flag} alt="flag-icon" className="subnav-icon" />
                        Apps
                    </Dropdown.Item>

                    <Dropdown.Divider />
                    <Dropdown.Item href={`/#${settingsPath}`}>
                        <img src={padlock} alt="padlock-icon" className="subnav-icon" />
                        Settings
                    </Dropdown.Item>
                    {!personal && <>
                        <Dropdown.Divider />
                        <Dropdown.Item href={`/#${permissionsPath}`}>
                            <img src={key} alt="key-icon" className="subnav-icon" />
                            Access
                        </Dropdown.Item>
                    </>}
                    {admin && <>

                        <Dropdown.Divider />
                        <Dropdown.Item href={`/#${billingPath}`}>
                            <img src={key} alt="treasure-chest-icon" className="subnav-icon" />
                            Billing
                        </Dropdown.Item>
                    </>}
                </Dropdown.Menu>
            </Dropdown>
        )
    }
}

export default OrganizationSubNav;
